import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

export const SidebarDataNonAdmin = [
    
     {
      title: "Support",
      path: "/support",
      icon: <IoIcons.IoMdHelpCircle />,
    },
    {
      title: "Contact",
      path: "../Contact",
      icon: <FaIcons.FaPhone />,
    },
    {   
      title: "About Us",
      path: "../Aboutus",
      icon: <AiIcons.AiFillApi />,
      iconClosed: <RiIcons.Ri4KFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
         
    },
  ];