import React, { useState, useEffect } from 'react'
import Modal from "react-bootstrap/Modal";
import { Container, Form, Button, Row , Col } from 'react-bootstrap'
import toastr from 'cogo-toast';
import validator from 'validator'
import axios from 'axios';


const AddNewTaskModalCustom =({isOpen, hideModal}) =>{
 
  //console.log("Called AddNewTaskModalCustom Ln 11 value of " + isOpen);
  const [CRMUsers, SetCRMUsers] = useState([]);	
  let [user, setUser] = useState("⬇️ Select an user ⬇️")
  const [taskdesc, setTaskDesc] = useState('');
  const [Phone, setPhoneNo] = useState('');
  const [email, setEmailId] = useState('');
  const [pCode, setPropertyCode] = useState('');
  const [interested, setInterestedIn] = useState('');
  const [created, setCreatedBy] = useState('Arijit');
  const [assigned, setAssignedTo] = useState('Arijit');
  const [targetDate, setDeadline] = useState('');
  const [taskComments, setComments] = useState('');
  const [oldComments, setOldComments] = useState('');
  const [prio, setPriority] = useState('');
  const [message, setMessage] = useState('');


  const [taskData, setTaskData] = useState({
    taskdesc: "",
    PhoneNo: "",
    emailId: "",
    propertyCode: "",
    interestedIn: "",
    createdBy: "Arijit",
    assignedTo: "Arijit",
    deadline: "",
    comments: "",
    oldComments:"",
    priority: false
  })

  useEffect(()=>{
    getCRMUsers();
  },[]);


/*
  useEffect((taskId)=>{
    fetchServiceData();

  },[taskId]);
 
  const fetchServiceData=async()=>{
      
    //let errorMsg = '';
    console.log("Task Id Ln 35 = " + taskId)
    const baseURL = "/taskById";
    const finalUrl = baseURL.toString()+"/"+taskId.toString();
    console.log("Final URL=" + finalUrl);
      try{
      const response = await axios.get(finalUrl);
      console.log("response= " + response.data)
      setTaskData(response.data); // Save seelcted task data into 

        setTaskDesc(response.data.taskDesc)
        setPhoneNo(response.data.phone_No)
        setEmailId(response.data.email_Id)
        setPropertyCode(response.data.property_Code)
        setInterestedIn(response.data.interested_In)
        setCreatedBy(response.data.taskCreatedBy)
        setAssignedTo(response.data.taskOwner)
        setDeadline(response.data.taskDeadline)
        setComments(response.data.comments)
        setPriority( response.data.taskPriority)
               
      //console.log("Data set inside Task.js = " + rowData.taskdesc +"--"+ rowData.PhoneNo+"--"+ rowData.emailId +"--"+ rowData.propertyCode +"--"+ rowData.interestedIn +"--"+ rowData.createdBy +"--"+ rowData.assignedTo +"--"+ rowData.deadline +"--"+ rowData.comments +"--"+ rowData.priority);
    
   }catch(error)
   {
       console.log(error);
       alert('Please check network connections..'+ error);
   }
 
   }
 */
   const getCRMUsers=async()=>{
    try{
       const response = await axios.get("/getCRMUsers");
       //const response = await axios.get("/tasks");
       SetCRMUsers(response.data);
    }catch(error)
    {
        console.log(error);
        alert('Please check network connections..'+ error);
    }
 
   }

    const handleTaskDesc=async(evt, param)=>{
   
    setTaskDesc(param);
    
   }

   const handleTaskComments=async(evt, param)=>{
    
    setComments(param);
    }

    const handleTaskPhone=async(evt, param)=>{
     
      setPhoneNo(param);
   }
    const handleTaskEmailId=async(evt, param)=>{
     
      setEmailId(param);
    }
    const handleTaskpCode=async(evt, param)=>{
    
    setPropertyCode(param);
    }
    const handleTaskInterested=async(evt, param)=>{
      
    setInterestedIn(param);
    }
    const handleTaskAssigned=async(evt, param)=>{
     //console.log("handleTaskAssigned  Ln 106= ", param);
      setAssignedTo(param);
    } 
    const handleTasktTargetDate=async(evt, param)=>{
      
      setDeadline(param);
      }
      const handleTaskCreatedBy=async(evt, param)=>{
       
        //console.log("handleTaskCreatedBy  Ln 115= ", param);
        setCreatedBy(param);
        }

        const reSetFormData=async()=>{
        
        setTaskDesc('');
        setPhoneNo('');
        setEmailId('');
        setPropertyCode('');
        setInterestedIn('');
        setCreatedBy('');
        setAssignedTo('');
        setDeadline('');
        setComments('');
        setPriority('');
        setOldComments('');

        }


    // Code to Save data by rest service call starts below....
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

    const checkClientValidation =()=>{

      if(taskdesc.length === 0)
      {
          alert("Please Enter task description..");
          return false;
      }
      if(Phone.length !== 10 )
      {
          alert("Please Enter a Valid Phone Number..");
          return false;
      }
      if(email.length === 0)
      {
          alert("Please Enter email Id..");
          return false;
      }
      if(pCode.length === 0)
      {
          alert("Please Enter propertyCode ..");
          return false;
      }
      if(interested.length === 0)
      {
          alert("Please Enter interestedIn..");
          return false;
      }
       if(!validator.isDate(targetDate)){
        alert("Please select Task Completion Date..");
          return false;

      }
      return true;

    }    

    let handleSubmit = async (e) => {
      //setShowProgres(true);
      e.preventDefault();
      if(!checkClientValidation()){

          return;

      }
      let errorMsg = '';
      const allFieldsFilled = () => {
        return(taskdesc !==''|| Phone !==''|| pCode !==''|| interested !==''|| targetDate !=='')
      };

    if(!allFieldsFilled)
    {
      errorMsg = 'Please fill out all the fields.';
      console.log(errorMsg);
    }
    else
    {
      
      const current = new Date();
      const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
      const finalComments = date.toString() +": " + taskComments;
      //console.log("Final Comments Ln 188 = " + finalComments);

      
      try {
        let res = await fetch("https://printsundar.online/loginAPI/addtask", {
          method: "POST",
          headers: {'Content-Type': 'application/json', 'charset': 'utf-8'},
          body: JSON.stringify({  
            taskId:(Math.floor(Math.random() * 10000) + 10000).toString().substring(1),
            attachments:"No Attachement",
            comments: finalComments,
            email_Id:email,
            interested_In:interested,
            phone_No:Phone,
            property_Code:pCode,
            taskAge: 0,
            taskCreatedBy:created,
            taskCreationDate: new Date(),
            taskDeadline:targetDate,
            taskDesc:taskdesc,
            taskOwner:assigned,
            taskPriority:"High",
            taskStatus:"Pending",
          }),
          
        });
        let resJson = await res.json();
        //setShowProgres(false);
        if (res.status === 200) {
           //setTaskData([]);
            //console.log("Task added successfully");
            setMessage("Task added successfully");
            toastr.success('New task added successfully!', {position : 'top-right', heading: 'Done'});    
            reSetFormData();
            hideModal();
         
        } else {
          //console.log("Some error occured");
          setMessage("Error occured...");
          toastr.error('Error to add task!', {position : 'top-right', heading: 'Failed'});
        }
      } catch (err) {
        console.log(err);
      }
    } 
    };
    
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // Code to Save data by rest service call ends above....
    return (
       <>  
      <Modal 
      show={isOpen} 
      onHide={hideModal} 
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
     >
      <Modal.Header style={{backgroundColor: "skyBlue"}} closeButton>
      <Modal.Title id="contained-modal-title-vcenter" color="white">
       Add New Task..
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="show-grid">
   
             <Form >
      <Form.Group controlId="formBasicEmail">
        <Row >
            <Col >
            <Form.Label>Task Description</Form.Label>
            <Form.Control type="taskDescription"  as="textarea" rows={2} 
            name="task"
            value={taskdesc}
            onChange={event=>handleTaskDesc(event, event.target.value)}
            />
            </Col>
           <Form.Group controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label="High Priority"  value={prio} 
        onChange={e => setPriority(e.target.checkbox)}
        />
      </Form.Group>
    </Row>
      </Form.Group>
   <Row>
    <Col>
    <Form.Group className="mb-3" controlId="phoneNo">
        <Form.Label>Phone No</Form.Label>
        <Form.Control type="number" placeholder="PhoneNo"  name="PhoneNo" value={Phone}
        onChange={event=>handleTaskPhone(event, event.target.value)}
        />
      </Form.Group>
    </Col>
    <Col>
    <Form.Group className="mb-3" controlId="emailId">
        <Form.Label>EmailId</Form.Label>
        <Form.Control type="text" placeholder="emailId" name="emailId"  value={email} 
         onChange={event=>handleTaskEmailId(event, event.target.value)}
        />
      </Form.Group>
    </Col>
   </Row>
     
   <Row>
    <Col>
    <Form.Group className="mb-3" controlId="propertyCode">
        <Form.Label>Property Code</Form.Label>
        <Form.Control type="text" placeholder="PropertyCode" name="propertyCode"  value={pCode}  
        onChange={event=>handleTaskpCode(event, event.target.value)}
        />
      </Form.Group>
    </Col>
    <Col>
    <Form.Group className="mb-3" controlId="interestedIn">
        <Form.Label>Interested In</Form.Label>
        <Form.Control type="text" placeholder="interestedIn" name="interestedIn" value={interested}   
       onChange={event=>handleTaskInterested(event, event.target.value)}
        />
      </Form.Group>
    </Col>
   </Row>
   <Row>
    <Col>
    <Form.Group className="mb-3" controlId="selectCreatedBy">
    <Form.Label>Created By</Form.Label>
      <Form.Control as="select"  
      name="createdBy" 
      value={ created }
      onChange={event=>handleTaskCreatedBy(event, event.target.value)}
       >
         <option value="Atanu">Atanu</option>
        <option value="Arijit">Arijit</option>
        <option value="Debojit">Debojit</option>
        <option value="Bibhas">Bibhas</option>
      </Form.Control>
      </Form.Group>
    </Col>
    <Col>
    <Form.Group className="mb-3" controlId="selectAssignedTo">
    <Form.Label>Assigned To</Form.Label>
      <Form.Control as="select"
       name="assignedTo" 
       value={ assigned}
       onChange={event=>handleTaskAssigned(event, event.target.value)}
       >
        <option value="Atanu">Atanu</option>
        <option value="Arijit">Arijit</option>
        <option value="Debojit">Debojit</option>
        <option value="Bibhas">Bibhas</option>
      </Form.Control>
      </Form.Group>
    </Col>
   </Row>
     <Row>
        <Col>
        <Form.Label>Task Completion Date</Form.Label>
        <Form.Control
                type="date"
                name="deadline"
                placeholder="DateRange"
                value={targetDate}
                onChange={event=>handleTasktTargetDate(event, event.target.value)}
              />
        </Col>
        <Col>
        
    <Form.Group className="mb-3" controlId="comments">
        <Form.Label>Comments</Form.Label>
        <Form.Control type="text" placeholder="Comments" name="comments" value={taskComments}   
        onChange={event=>handleTaskComments(event, event.target.value)}
        />
      </Form.Group>
    
        </Col>
     </Row>
      <div className="message">{message ? <p>{message}</p> : null}</div>
    </Form>
      
        </Modal.Body>
      <Modal.Footer>
        <Button color="primary" onClick={hideModal}>Cancel</Button>
        <Button color="dark" onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
    </>
    )
  }

export default AddNewTaskModalCustom