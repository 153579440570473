import React, { useState, useEffect } from 'react'
import Modal from "react-bootstrap/Modal";
import { Container, Form, Button, Row , Col } from 'react-bootstrap'
import toastr from 'cogo-toast';
import validator from 'validator'
import axios from 'axios';
import useAuth from "./hooks/useAuth";

const ModalCustomAdmin =({isOpen, hideModal, printOrderId}) =>{
  console.log(" Ln 10 ModalCustomAdmin called = " + printOrderId);
  const { auth } = useAuth();  
 
  const [Phone, setPhoneNo] = useState('');
  //imageId
  const [imageId, setImageId] = useState();
  //const [printOrderId, setprintOrderId] = useState(printId);
  const [email, setEmailId] = useState('');
  const [pCode, setPropertyCode] = useState('');
  const [interested, setInterestedIn] = useState('');
  const [created, setCreatedBy] = useState('');
  const [assigned, setAssignedTo] = useState('');
  const [targetDate, setDeadline] = useState('');
  const [taskComments, setComments] = useState('');
  const [oldComments, setOldComments] = useState('');
  const [prio, setPriority] = useState('');
  const [message, setMessage] = useState('');
  const [taskStatus, setTaskStatus] = useState('');

  const [userImageURL, setuserImageURL] = useState('');
  //userCreatedFrontImagePath
  //const [userfrntImage, setUserfrntImage] = useState('');
  
  //oldComments

  const [designerImageData, SetDesignerImageData] = useState({
    userfrontImage:"",
    userCreatedFrontImagePath:"",
    userbackImage:"",
    usercreatedBackimagePath:""
  });
  
  const serverCreate = "https://nodejsrazorpay.onrender.com/razorpay";

  var getImagefromId = "https://printsundar.online/loginAPI/download/frontImageuserFile/";
  var finaluserImageUrl = "";

  const [printData, setPrintData] = useState({
   taskOwner:"",
comments:"",
taskStatus:"",
userNam:"",
phoneNo:null,
bookingType:"",
registrationNo:"",
bookingDatetime:"",
users:[],
printOrderId:1,
due:null,
firstPayment:0,
secondPayment:0,
firstPaymentTranId:"",
secondPaymentTranId:"",
orderNam:"",
cardType:"",
cardSize:"",
mediaType:"",
quality:"",
gsmValue:"",
quantity:"",
qrcode:false,
lamination:false,
spotUV:false,
texture:false,
transparent:false,
specialColor:false,
smbossing:false,
boolUploadFrontImage:false,
boolUploadBackImage:false,
qrsingleBoth:"",
laminationSingleBoth:"",
spotUVSingleBoth:"",
textureSingleBoth:"",
transparentSingleBoth:"",
specialColorSingleBoth:"",
embossingSingleBoth:"",
frontImagePath:null,
backImagePath:null,
qrcodeImagePath:null,
price:0,
taskType:"Print"
});


useEffect((printOrderId)=>{
    fetchServiceData();

  },[printOrderId]);
  
  //const [printOrderId, setPrintOrderId] = useState(1);

   //handleTaskStatus
   const handleTaskStatus=async(evt, param)=>{
     
    console.log(" Ln 193 Task Assigned to = " + param);
    setTaskStatus(param);
    }
 
  const fetchServiceData=async()=>{
      
    //let errorMsg = '';
    //setprintOrderId(printId);
    const getImageIdURL = "https://printsundar.online/loginAPI/download/getImageId/"+ printOrderId;
    //console.log("fetchServiceData Called printOrderId 108 = " + printOrderId);
    console.log(" Line 112 fetchServiceData getImageIdURL = " + getImageIdURL)
    //getImageIdURL
    
     //var taskId = 7;
     //setPrintOrderId(7);

     //setPrintOrderId
    const baseURL = "https://printsundar.online/loginAPI/PrintOrderId";
   
   try{
    console.log(" Ln 124 getImageIdURL = " + getImageIdURL);
        await axios.get(getImageIdURL).then((response)=>{

          console.log("Line 127 Image ID = " + response.data);
          setImageId(response.data);
          finaluserImageUrl = getImagefromId.toString() + response.data;
          console.log("Line 130 getImagefromId = " + finaluserImageUrl);
          setuserImageURL(finaluserImageUrl);
        }) .catch((err) => console.log(err));
           
     }catch(exception)
     {

      console.log(exception);
     }
    
    const finalUrl = baseURL.toString()+"/"+printOrderId.toString();
    console.log("Line 141 ModalAdmin.js Final URL=" + finalUrl);
      try{
      const response = await axios.get(finalUrl);
      //console.log("Line 121 Modal Custom Databse Response= " + response.data);
      setPrintData(response.data);
      //setTaskData(response.data); // Save seelcted task data into 
      //calculateDuePayment(response.data.printOrderId, response.data.price, response.data.firstPayment);

        //setTaskDesc(response.data.taskDesc)
        setPhoneNo(response.data.phone_No)
        setEmailId(response.data.email_Id)
        setPropertyCode(response.data.property_Code)
        setInterestedIn(response.data.interested_In)
        setCreatedBy(response.data.taskCreatedBy)
        setAssignedTo(response.data.taskOwner)
        setDeadline(response.data.taskDeadline)
        setTaskStatus(response.data.taskStatus)
        //setComments(response.data.comments)
        const final = response.data.oldComments +": \n " + response.data.comments;
        setOldComments(final);
        setPriority( response.data.taskPriority)
               
      //console.log("Data set inside Task.js = " + rowData.taskdesc +"--"+ rowData.PhoneNo+"--"+ rowData.emailId +"--"+ rowData.propertyCode +"--"+ rowData.interestedIn +"--"+ rowData.createdBy +"--"+ rowData.assignedTo +"--"+ rowData.deadline +"--"+ rowData.comments +"--"+ rowData.priority);
    
   }catch(error)
   {
       console.log(error);
       alert('Please check network connections..'+ error);
   }
 
   }

   /* const handleTaskDesc=async(evt, param)=>{
   
    setTaskDesc(param);
    
   }
   */

   const handleTaskComments=async(evt, param)=>{
              setComments(param);
    }

   /* const handleOldTaskComments=async(evt, param)=>{
      setOldTaskComments(param);
    }
    */

    const handleTaskPhone=async(evt, param)=>{
     
      setPhoneNo(param);
   }
    const handleTaskEmailId=async(evt, param)=>{
     
      setEmailId(param);
    }
    const handleTaskpCode=async(evt, param)=>{
    
    setPropertyCode(param);
    }
    const handleTaskInterested=async(evt, param)=>{
      
    setInterestedIn(param);
    }
    const handleTaskAssigned=async(evt, param)=>{
     
      console.log(" Ln 193 Task Assigned to = " + param);
      setAssignedTo(param);
    } 
   
    const handleTasktTargetDate=async(evt, param)=>{
      
      setDeadline(param);
      }
      const handleTaskCreatedBy=async(evt, param)=>{
       
        setCreatedBy(param);
        }

    // Code to Save data by rest service call starts below....
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

    const checkClientValidation =()=>{

     /* if(taskdesc.length === 0)
      {
          alert("Please Enter task description..");
          return false;
      }
      */
      if(Phone.length !== 10 )
      {
          alert("Please Enter a Valid Phone Number..");
          return false;
      }
      if(email.length === 0)
      {
          alert("Please Enter email Id..");
          return false;
      }
      if(pCode.length === 0)
      {
          alert("Please Enter propertyCode ..");
          return false;
      }
      if(interested.length === 0)
      {
          alert("Please Enter interestedIn..");
          return false;
      }
       if(!validator.isDate(targetDate)){
        alert("Please select Task Completion Date..");
          return false;

      }
      return true;

    }    

    let handleSubmit = async (e) => {
      e.preventDefault();
     /* if(!checkClientValidation()){

          return;

      }
      
      let errorMsg = '';
      const allFieldsFilled = () => {
        return( Phone !==''|| pCode !==''|| interested !==''|| targetDate !==''||taskComments !=='')
      };
    */
      let errorMsg = '';
      if(taskComments == '')
      {
        errorMsg = 'Please fill task update as comments.';
        alert(errorMsg);
        return;
      }
    else
    {
      try {
        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
        const finalComments = date.toString() +"/ By "+auth.user+": " + taskComments ;
        //console.log("Final Comments Ln 188 = " + finalComments);
        /*
        const body =
       {
        taskId: 143,
        taskCreationDate: "2022-10-15T08:36:02.259Z",
        taskDesc: "Hello taskdes Barun123",
        phone_No: "9433510469",
        interested_In: "Banglow Villa2",
        taskStatus: "High",
        taskCreatedBy: "Debojit",
        comments: "Urgent Requirement--Barun2",
        property_Code: "P0012A123",
        email_Id: "barun@gmail.com",
        taskAge: 0,
        attachments: "No Attachement",
        taskPriority: "High",
        taskOwner: "Bibhas",
        taskDeadline: "2022-10-30"
      }; 
      */
     
     const body =
      {
        printOrderId: printData.printOrderId,
        taskStatus: taskStatus,
        comments: finalComments,
        oldComments: oldComments,
        taskOwner: assigned,
        }; 
        console.log("Going to update printOrder request");
       axios.put("https://printsundar.online/loginAPI/updatePrintOrder",
          body  
         )
        .then(response => {  
          setMessage("Task Updated successfully");
          toastr.success('Task Updated successfully...', {position : 'top-right', heading: 'Done'});    
          hideModal();
      })
        .catch(error => {
          console.log("Error to Update" + error);
          setMessage("Error occured to Update Task...");
          toastr.error('Error to Update task!', {position : 'top-right', heading: 'Failed'});
        });

      } catch (err) {
        console.log("*** Error to Update Task= "+ err);
      }
    }
      
    };
    
 
    const handleChangeFrontImageFileUpload = async(event)=>{
 
     const value = (event.target.files[0]);
     console.log("Front Image File  = " + value.name);
     console.log("event.target.name  = " + event.target.name);
     //setUserfrntImage(URL.createObjectURL(event.target.files[0]));
      SetDesignerImageData({
        ...designerImageData,
        [event.target.name]: URL.createObjectURL(event.target.files[0])
      });
      SetDesignerImageData((designerImageData) => ({ ...designerImageData, userCreatedFrontImagePath: event.target.files[0]}));
      var imageUploadUrl = "";
      const data = new FormData();
      if (designerImageData.userfrontImage != null)
        {
            imageUploadUrl =  "https://printsundar.online/loginAPI/upload1FrontImageFileDesigner/10";
          
            data.append('file1', designerImageData.userfrontImage);
            
         }
          let res = await fetch(
            imageUploadUrl,
            {
              method:'POST',
              mode:'no-cors',
              body:data,
              //headers: {
                //'content-type': item.frontImageFile.type,
                //'content-length': `${item.frontImageFile.size}`, // 👈 Headers need to be a string
             // }
              
            }
          ).then(async response=>{
              const data = await response.json();
              // check for error response
            if (!response.ok) {
              // get error message from body or default to response statusText
              const error = (data && data.message) || response.statusText;
              return Promise.reject(error);
          }
          else{

             console.log("Succesfully saved IMAGES into attachments table")
          }


          });
        }
          
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // Code to Save data by rest service call ends above....
    return (
      <> <Modal 
      show={isOpen} 
      onHide={hideModal} 
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
     >
     <Modal.Header style={{backgroundColor: "skyBlue"}} closeButton>
      <Modal.Title id="contained-modal-title-vcenter" color="white">
      Details of Order Id: {printOrderId}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="show-grid">
   
             <Form >
      <Form.Group controlId="formBasicEmail">
        <Row >
            <Col >
            <img
                                 src= {userImageURL} // //item.frontImagePath
                                  // className="w-100"
                                  //imageId
                                  alt="image"
                                  width={400}
                                  height={400}
                                />
                                 <Form.Label>User Provided Front Image</Form.Label>
            
            </Col>
            <Col>
            <img
                                  src= {designerImageData.userfrontImage}   // //item.frontImagePath
                                  // className="w-100"
                                  alt="userCreatedimage"
                                  width={400}
                                  height={400}
                                />
                                 <Form.Label>Image Created by Designer</Form.Label>
                                 <input id="uploadfront" type="file" accept="image/*" name="userfrontImage"  onChange={handleChangeFrontImageFileUpload}
           />
            
            </Col>
    </Row>
      </Form.Group>
      <div> <hr/></div>
   <Row>
    <Col>
    <Form.Group className="mb-3" controlId="phoneNo">
        <Form.Label>Order Name:</Form.Label>
        <Form.Control type="text" placeholder="orderName"  name="orderName" defaultValue={printData.orderNam}
        />
      </Form.Group>
    </Col>
    <Col>
    <Form.Group className="mb-3" controlId="emailId">
    <Form.Label>Booking Date/ Time:</Form.Label>
        <Form.Control type="text" placeholder="bookingDate"  name="bookingDate" defaultValue={printData.bookingDatetime}
        />
      </Form.Group>
    </Col>
   </Row>
     
   <Row>
   <Col>
    <Form.Group className="mb-3" controlId="phoneNo">
        <Form.Label>Task Status:</Form.Label>
        <Form.Control type="text" placeholder="taskStatus"  name="taskStatus" defaultValue={printData.taskStatus}
        />
      </Form.Group>
    </Col>
    <Col>
    <Form.Group className="mb-3" controlId="emailId">
    <Form.Label>Booking Type</Form.Label>
        <Form.Control type="text" placeholder="bookingType"  name="bookingType" defaultValue={printData.bookingType}
        />
      </Form.Group>
    </Col>
   </Row>
   <Row>
   <Col>
    <Form.Group className="mb-3" controlId="cardType">
        <Form.Label>Card Type</Form.Label>
        <Form.Control type="text" placeholder="cardType"  name="cardType" defaultValue={printData.cardType}
        />
      </Form.Group>
    </Col>
    <Col>
    <Form.Group className="mb-3" controlId="emailId">
    <Form.Label>Card Size</Form.Label>
        <Form.Control type="text" placeholder="cardSize"  name="cardSize" defaultValue={printData.cardSize}
        />
      </Form.Group>
    </Col>
   </Row>
     <Row>
     <Col>
    <Form.Group className="mb-3" controlId="mediaType">
        <Form.Label>Media Type</Form.Label>
        <Form.Control type="text" placeholder="mediaType"  name="mediaType" defaultValue={printData.mediaType}
        />
      </Form.Group>
    </Col>
    <Col>
    <Form.Group className="mb-3" controlId="quality">
    <Form.Label>Quality:</Form.Label>
        <Form.Control type="text" placeholder="quality"  name="quality" defaultValue={printData.quality}
        />
      </Form.Group>
    </Col>
        </Row>
        <Row>
     <Col>
    <Form.Group className="mb-3" controlId="gsmValue">
        <Form.Label>GSM Valuee</Form.Label>
        <Form.Control type="text" placeholder="gsmValue"  name="gsmValue" defaultValue={printData.gsmValue}
        />
      </Form.Group>
    </Col>
    <Col>
    <Form.Group className="mb-3" controlId="quantity">
    <Form.Label>Quantity:</Form.Label>
        <Form.Control type="text" placeholder="quantity"  name="quantity" defaultValue={printData.quantity}
        />
      </Form.Group>
    </Col>
        </Row>
        <Row>
     <Col>
    <Form.Group className="mb-3" controlId="qrcode">
        <Form.Label>QR Code</Form.Label>
        <Form.Control type="text" placeholder="qrcode"  name="qrcode" defaultValue={printData.qrcode}
        />
      </Form.Group>
    </Col>
    <Col>
    <Form.Group className="mb-3" controlId="qrsingleBoth">
    <Form.Label>QR Single/Both:</Form.Label>
        <Form.Control type="text" placeholder="qrsingleBoth"  name="qrsingleBoth" defaultValue={printData.qrsingleBoth}
        />
      </Form.Group>
    </Col>
        </Row>

        <Row>
     <Col>
    <Form.Group className="mb-3" controlId="lamination">
        <Form.Label>Lamination </Form.Label>
        <Form.Control type="text" placeholder="lamination"  name="lamination" defaultValue={printData.lamination}
        />
      </Form.Group>
    </Col>
    <Col>
    <Form.Group className="mb-3" controlId="laminationSingleBoth">
    <Form.Label>Lamination Single/Both:</Form.Label>
        <Form.Control type="text" placeholder="laminationSingleBoth"  name="laminationSingleBoth" defaultValue={printData.laminationSingleBoth}
        />
      </Form.Group>
    </Col>
        </Row>
        <Row>
     <Col>
    <Form.Group className="mb-3" controlId="spotUV">
        <Form.Label>Spot UV</Form.Label>
        <Form.Control type="text" placeholder="spotUV"  name="spotUV" defaultValue={printData.spotUV}
        />
      </Form.Group>
    </Col>
    <Col>
    <Form.Group className="mb-3" controlId="spotUVSingleBoth">
    <Form.Label>Spot UV Single/Both:</Form.Label>
        <Form.Control type="text" placeholder="spotUVSingleBoth"  name="spotUVSingleBoth" defaultValue={printData.spotUVSingleBoth}
        />
      </Form.Group>
    </Col>
        </Row>
        <Row>
     <Col>
    <Form.Group className="mb-3" controlId="texture">
        <Form.Label>Texture</Form.Label>
        <Form.Control type="text" placeholder="texture"  name="texture" defaultValue={printData.texture}
        />
      </Form.Group>
    </Col>
    <Col>
    <Form.Group className="mb-3" controlId="textureSingleBoth">
    <Form.Label>Texture Single/Both</Form.Label>
        <Form.Control type="text" placeholder="textureSingleBoth"  name="textureSingleBoth" defaultValue={printData.textureSingleBoth}
        />
      </Form.Group>
    </Col>
        </Row>
        <Row>
     <Col>
    <Form.Group className="mb-3" controlId="transparent">
        <Form.Label>Transparent</Form.Label>
        <Form.Control type="text" placeholder="transparent"  name="transparent" defaultValue={printData.transparent}
        />
      </Form.Group>
    </Col>
    <Col>
    <Form.Group className="mb-3" controlId="transparentSingleBoth">
    <Form.Label>Transparent Single/Both:</Form.Label>
        <Form.Control type="text" placeholder="transparentSingleBoth"  name="transparentSingleBoth" defaultValue={printData.transparentSingleBoth}
        />
      </Form.Group>
    </Col>
        </Row>
        <Row>
     <Col>
    <Form.Group className="mb-3" controlId="specialColor">
        <Form.Label>Special Color</Form.Label>
        <Form.Control type="text" placeholder="specialColor"  name="specialColor" defaultValue={printData.specialColor}
        />
      </Form.Group>
    </Col>
    <Col>
    <Form.Group className="mb-3" controlId="specialColorSingleBoth">
    <Form.Label>Special Color Single/Both:</Form.Label>
        <Form.Control type="text" placeholder="specialColorSingleBoth"  name="specialColorSingleBoth" defaultValue={printData.specialColorSingleBoth}
        />
      </Form.Group>
    </Col>
        </Row>
        <Row>
     <Col>
    <Form.Group className="mb-3" controlId="price">
        <Form.Label>Price</Form.Label>
        <Form.Control type="text" placeholder="price"  name="price" defaultValue={printData.price}
        />
      </Form.Group>
    </Col>
    <Col>
    <Form.Group className="mb-3" controlId="firstPayment">
    <Form.Label>Advance Payment</Form.Label>
        <Form.Control type="text" placeholder="firstPayment"  name="firstPayment" defaultValue={printData.firstPayment}
        />
      </Form.Group>
    </Col>
        </Row>
        <Row>
     <Col>
    <Form.Group className="mb-3" controlId="firstPaymentTranId">
        <Form.Label>Advance Payment TranId</Form.Label>
        <Form.Control type="text" placeholder="firstPaymentTranId"  name="firstPaymentTranId" defaultValue={printData.firstPaymentTranId}
        />
      </Form.Group>
    </Col>
    <Col>
    <Form.Group className="mb-3" controlId="secondPayment">
    <Form.Label>Second Payment:</Form.Label>
        <Form.Control type="text" placeholder="secondPayment"  name="quality" defaultValue={printData.secondPayment}
        />
      </Form.Group>
    </Col>
        </Row>
        <Row>
     <Col>
    <Form.Group className="mb-3" controlId="secondPaymentTranId">
        <Form.Label>Second Payment TranId</Form.Label>
        <Form.Control type="text" placeholder="secondPaymentTranId"  name="secondPaymentTranId" defaultValue={printData.secondPaymentTranId}
        />
      </Form.Group>
    </Col>
    <Col>
    <Form.Group className="mb-3" controlId="phoneNo">
    <Form.Label>Phone No:</Form.Label>
        <Form.Control type="text" placeholder="phoneNo"  name="phoneNo" defaultValue={printData.phoneNo}
        />
      </Form.Group>
    </Col>
        </Row>
       <Row>
       <Col>
    <Form.Group className="mb-3" controlId="selectAssignedTo">
    <Form.Label>Assigned To</Form.Label>
      <Form.Control as="select"
       name="assignedTo" 
       value={ assigned}
       onChange={event=>handleTaskAssigned(event, event.target.value)}
       >
        <option value="Designer">Designer</option>
        <option value="Marketing">Marketing</option>
        <option value="Ranjan">Ranjan</option>
        <option value="Barun">Barun</option>
      </Form.Control>
      </Form.Group>
    </Col>
    <Col>
    <Form.Group className="mb-3" controlId="selectTask Status">
    <Form.Label>Task Status</Form.Label>
      <Form.Control as="select"
       name="taskStatus" 
       value={ taskStatus}
       onChange={event=>handleTaskStatus(event, event.target.value)}
       >
        <option value="Open">Open</option>
        <option value="Pending">Pending</option>
        <option value="Close">Close</option>
        <option value="Progress">Progress</option>
      </Form.Control>
      </Form.Group>
    
    </Col>


       </Row>

        <Row>
        <Col>
      <Form.Group className="mb-3" controlId="oldComments">
        <Form.Label>Comments History</Form.Label>
        <Form.Control type="text" disabled="true" placeholder="oldComments"  as="textarea" rows={5} name="oldComments" value={oldComments} 
        />
      </Form.Group>
    
        </Col>
     </Row>
        <Row>
        <Col>
      <Form.Group className="mb-3" controlId="comments">
        <Form.Label>Comments</Form.Label>
        <Form.Control type="text" placeholder="Comments"  as="textarea" rows={2} name="comments" value={taskComments}   
        onChange={event=>handleTaskComments(event, event.target.value)}
        />
      </Form.Group>
    
        </Col>
     </Row>
      <div className="message">{message ? <p>{message}</p> : null}</div>
    </Form>
      
        </Modal.Body>
      <Modal.Footer>
        <Button color="primary" onClick={hideModal}>Cancel</Button>
        <Button color="dark" onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal></>
    )
  }

export default ModalCustomAdmin