import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
//import { SidebarData } from "./SidebarData";
import { SidebarDataNonAdmin } from "./SidebarDataNonAdmin";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import useAuth from "./hooks/useAuth";

const NavTop = styled.div`
  background: #0A1063;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
  
const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
  
const SidebarNav = styled.nav`
  background: #0A1063;
  width: 200px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top:0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
`;
  
const SidebarWrap = styled.div`
  width: 100%;
`;
  
const InputTextBox = styled.input.attrs(props => ({
  // we can define static props
  type: "text",
  placeholder: "Search people",
  // or we can define dynamic ones
  size: 40 || "0.1em",
  
}))`
  color: black;
  font-size: 1em;
  border: 1px solid black;
  border-radius: 2px;

  /* here we use the dynamically computed prop */
  margin:  5px;
  padding: 5px;
  margin-left: 20px;
  margin-right: 15px;
  margin-bottom: 5px;
  align-items:left;
`;

  
const SidebarNonAdmin = () => {
  const [sidebar, setSidebar] = useState(true);
  var [date,setDate] = useState(new Date());
  //const showSidebar = () => setSidebar(!sidebar);
  const showSidebar = () => setSidebar(true);
  const { auth } = useAuth();
  useEffect(() => {
    var timer = setInterval(()=>setDate(new Date()), 1000 )
    return function cleanup() {
        clearInterval(timer)
    }

});
  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <NavTop>
          <NavIcon to="#">
            <FaIcons.FaBars onClick={showSidebar} />
          </NavIcon>
          <h1
            style={{ textAlign: "center", 
                     marginLeft: "200px", 
                     color: "green" }}
          >
            CRM 1.0
          </h1>
          <InputTextBox/>
          <h2
            style={{ textAlign: "right", 
                     marginLeft: "50px", 
                     color: "white" }}
          >
             {date.toLocaleTimeString()}
          </h2>
          <h5
            style={{ textAlign: "right", 
                     marginLeft: "50px", 
                     color: "white" }}
          >
              Welcome {auth.user}
          </h5>
        </NavTop>
        <SidebarNav sidebar={sidebar}>
          <SidebarWrap>
            <NavIcon to="#">
              <AiIcons.AiOutlineClose onClick={showSidebar} />
            </NavIcon>
            {SidebarDataNonAdmin.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};
  
export default SidebarNonAdmin;