import React from 'react'
import styled from "styled-components";
import Koala from '../src/img/Koala.jpg';


const NavUser = styled.div`
  background: #9ca5b5;
  margin-top: 1px;
  width: (100hh-100);
  height: 100px;
  margin-bottom: 0.5px;
  display: flex;
  margin-left: 205px;
  justify-content: left;
  border-radius: 10px;
  align-items: center;
  border:solid 1px #0000ff;

`;
const TextHeader1 = styled.h1`
  font-size:  1.5em;
  text-align: center;
  justify-content: center;
  margin-left: 10px;
  color: blue;
   `;

const NavUserDesc = styled.div`
  
  flex-direction: column;
  justify-content: start;
  align-items: center;
  border-radius: 10px;
`;
const UserInfoDiv = styled.div`
display: flex;
max-width: 100%;
max-height: 100%;


`;
const NavUserDescLeft = styled.form`
 flex-direction: column;
 height: 470px;
  width: 100hh-100;
  margin-left: 205px;
  margin-top:1px;
  background-color:#6eb5c4;
  border-radius: 5px;

  `;
const UserImage = styled.img`
width: 80px;
height: 80px;
padding: 1px;
border-radius: 50px;
`;
const BookingHistoryData =[

  {id:'ACD101', name: 'A. Kumar', contactno: '9433510469',jobsinhand:'2',status:'Available'},
  {id:'ACD102', name: 'B. Sharma', contactno: '9433510469',jobsinhand:'3',status:'Available'},
  {id:'ACD103', name: 'P. Pal', contactno: '9433510469',jobsinhand:'2',status:'Available'},
  
]



export const Technicians = () => {
  return (

     <>
        <NavUser>
         <NavUserDesc>
          <TextHeader1 > Technicians</TextHeader1>
      </NavUserDesc>
      </NavUser>
      <NavUserDescLeft>
      <table className='table table-primary table-bordered'>
       <thead>
         <tr>
          <th> Technician</th>
          <th> Id</th>
          <th> Contact No</th>
          <th> Jobs in Hand</th>
          <th> Status</th>
         </tr>
       </thead>
       <tbody>
          {
            BookingHistoryData.map((item,i)=>
            <tr key={i}>
            <td>
             <UserImage src="https://mdbootstrap.com/img/new/avatars/7.jpg" />
             {  item.name}
             </td>
              <td>{item.id}</td>
              <td>{item.contactno}</td>
              <td>{item.jobsinhand}</td>
              <td>{item.status}</td>
              <td><button type="button" class="btn btn-primary">Tasks</button></td>
            </tr>)
          }
        </tbody>
      </table>
      </NavUserDescLeft>
    


     </>
    
  )
}
