import {React, useState, useEffect  } from 'react'
import Web from '../src/img/StarUp.PNG';
import styled from 'styled-components';
import {Routes, Route, useNavigate} from 'react-router-dom';
import CountriesTable from './components/CountriesTable';
import TasksUserRole from './components/TasksUserRole';
import CustomtDialog from './components/CustomDialog';
import {LoginForm} from './components/Forms/LoginForm/LoginForm';
//import ModalDlg from './components/ModalDlg';
import Sidebar from './components/Sidebar';
import { Link } from "react-router-dom";
import { useContext } from "react";

import useAuth from "../src/components/hooks/useAuth";

import Home from "./Home";
import AuthContext from "./components/context/AuthProvider";
import {Container, Nav,  Navbar, NavDropdown, Row, Col, Card } from 'react-bootstrap';
import './Home.css';


const ExtraPadding = styled.div`
margin-left: 20px;
`;
const ExtraleftPadding = styled.div`
margin-left: 250px;
`;
const ExtraBottomPad = styled.div`
margin-bototom: 20px;
`;
const DropDownStyle = styled.div`
  margin: 100;
  width: 250;
`;
const TaskButton = styled.button`
margin-left: 10px;
background:green;
font-size: 1em;
  margin: 1em;
  width: 100px;
  height: 40px;
  padding: 0.25em 1em;
  border: 2px solid white;
  border-radius: 3px;

`;
const NavUser = styled.div`
  background: #4d9dff;
  margin-top: 1px;
  margin-bottom: 15px;
  margin-left: 10px;
  margin-right: 2px;
  height: 350px;
  width:100%;
  border:solid 1px #0000ff
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const LabelUser = styled.span`
  margin-left: 10px;
`;
const NavTop = styled.div`
  background: #0A1063;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const InputTextBox = styled.input.attrs(props => ({
  // we can define static props
  type: "text",
  placeholder: "Search Tasks",
  // or we can define dynamic ones
  size: 40 || "0.1em",
  
}))`
  color: black;
  font-size: 1em;
  border: 1px solid black;
  border-radius: 2px;

  /* here we use the dynamically computed prop */
  margin:  5px;
  padding: 5px;
  margin-left: 20px;
  margin-right: 15px;
  margin-bottom: 5px;
  align-items:left;
`;

export const HomeUser = () => {
  var [date,setDate] = useState(new Date());
  const [userInfo, setUserInfo] = useState([]);  // setting user info 
  const [modal, setModal] = useState(false);

  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const { auth } = useAuth();
  useEffect(() => {
    var timer = setInterval(()=>setDate(new Date()), 1000 )
    return function cleanup() {
        clearInterval(timer)
    }

});
  //console.log("Auth Info Ln 102 = "+ auth.user);
  const handleLogout = async () => {
    // if used in more components, this should be in context 
    // axios to /logout endpoint 
    setAuth({});
    navigate('/');
  }
const handleLogIn=()=>{
  
  navigate('/LoginForm');
}
  // Getting User Info during loading of the Page
    
  useEffect(() => {
    const getcategory = async () => {
      const finalUrl = "https://printsundar.online/loginAPI/PrintOrders/" + auth.user;
      console.log("***** Final URL = " + finalUrl);
      const res = await fetch(finalUrl);
      const getdata = await res.json();
      setUserInfo(getdata);
      // console.log(getdata);
    };

    getcategory();
  },[]);
  
  const onClickProductSelected=(name, email, phone)=>{
   //console.log("Button Clicked.." , {name} , {email}, {phone});
   }
    

   return (
    <div>
      
       <NavUser>
      <NavTop>
      <h2
            style={{ textAlign: "left", 
                     marginLeft: "25px", 
                     marginright: "25px",
                     color: "white" }}
          >
            Print Sundar CRM 1.0      
      </h2>
      <h2
            style={{ textAlign: "right", 
                     marginLeft: "100px", 
                     color: "white" }}
          >
            Welcome {auth.user}
          </h2>
      <h2
            style={{ textAlign: "right", 
                     marginLeft: "200px", 
                     color: "white" }}
          >
             {date.toLocaleTimeString()}
          </h2>
        <ExtraleftPadding/>
           <button type="button" class="btn btn-info" onClick={() => 
            handleLogout()}>Logout</button>              
     
      </NavTop>
       < TasksUserRole/>   
     </NavUser>
    </div>
    
    )
 }
