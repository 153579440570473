import React from 'react'
import styled from "styled-components";
import Koala from '../src/img/Koala.jpg';
import ReactDOM from 'react-dom/client';
import { useState } from 'react'; 



const UpcomingBookingData =[

  {id:'ACD102', desc: 'Basic Booking Service', date: '30th July 2022',time:'10 am',status:'Pending', comments:'No Comments'},
  
]

const BookingHistoryData =[

  {id:'ACD101', desc: 'Basic Booking Service', date: '10th July 2021',time:'11 am',status:'Done', comments:'No Comments'},
  {id:'ACD102', desc: 'Basic Booking Service', date: '20th Dec 2021',time:'2 pm',status:'Done', comments:'No Comments'},
  {id:'ACD103', desc: 'Basic Booking Service', date: '15th June 2022',time:'4 pm',status:'Done', comments:'No Comments'},
  
]
  
const NavUser = styled.div`
  background: #9ca5b5;
  margin-top: 3px;
  width: (100hh-100);
  margin-bottom: 0.5px;
  display: flex;
  margin-left: 205px;
  justify-content: left;
  align-items: center;
  border:solid 1px #0000ff;
 border-radius: 15px;

`;
const UserInfoDiv = styled.div`
display: flex;
max-width: 100%;
max-height: 100%;


`;

const NavUserDesc = styled.div`
  
  flex-direction: column;
  justify-content: start;
  align-items: center;
  border-radius: 10px;
`;
const NavUserDescLeft = styled.form`
 flex-direction: column;
 height: 470px;
  width: 30%;
  margin-left: 205px;
  margin-top:1px;
  background-color:#6eb5c4;
  border-radius: 10px;

  `;
  const NavUserDescRight = styled.form`
  height: 470px;
  width: 70%;
  margin-left: 2px;
  margin-top:1px;
  background-color:#6eb5c4;
  border-radius: 10px;

  `;

const FormControl = styled.div`
 padding: 15px;
 :first-of-type{
    margin: 2px;
 }
 :last-child{
  margin:2px;
 }

width: 100%;
`;
const RowDiv = styled.div`
  display: flex;
  max-width: 100%;
`;

const InputTextBox = styled.input.attrs(props => ({
  // we can define static props
  type: "text",
  // or we can define dynamic ones
  size: 28 || "0.1em",
  
}))`
  color: black;
  font-size: 1em;
  border: 1px solid black;
  border-radius: 2px;

  /* here we use the dynamically computed prop */
  margin: ${props => props.size} + 5px;
  padding: ${props => props.size};
  margin-right: 5px;
  margin-bottom: 5px;
  align-items:center;
`;

const UserInput = styled.input`
  flex: 1 0;
  min-width: 50px;
  min-height: 25px;
  font-size: inherit;
  background-color: transparent;
  padding-left: 5px;
  border: 0;
  &:focus {
    outline: none;
  }
`;

  const userButton = styled.button`
  
  
  `;


const UserImage = styled.img`
    width: 100px;
    height: 100px;
    padding: 2px;
    border-radius: 50px;
`;
const NavuserPic = styled.div`
   background: #4178d9;
   width: 100px;
   height: 100px;
   border: solid 1px #c74a28;
   margin-left: 12px;
  margin-top: 12px;
  align-items: flex-start;
  justify-content: start;
  border-radius: 80px;
`;

const UpcomingBookingDiv =styled.div`

   display: flex;
   flex-direction: column;
   background-color: #fff; 
   width: 90%;
   max-height: 40%;
   margin-top: 5px;
   margin-left: 5px;
`;


const TextHeader1 = styled.h1`
  font-size:  2.0em;
  text-align: center;
  justify-content: center;
  color: blue;
   `;

const UserLabel = styled.h1`
  font-size:  1.0em;
  color: black;
  margin-right: 5px;
   `;

  const TextHeader2 = styled.h2`
   font-size:  1.5em;
   text-align: center;
   color: blue;
  margin-left: 2px;

  `;
  const TextHeader3 = styled.h3`
  font-size:  2em;
  text-align: center;
  color: blanchedalmond;
 margin-left: 2px;

 `;

const TextHeader4 = styled.h4`
font-size:  1.1em;
text-align: left;
color: blue;
margin-left: 10px; 
`;


  const TextHeaderLabel = styled.label`
  font-size:  1em;
  color: black;
 margin-left: 2px;

 `;

 export const UserDetails = () => {
    const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    alert(inputs);
  }

  return (
    <>
    <NavUser>
      <NavuserPic>
        <UserImage src={Koala} />
      </NavuserPic>
      <NavUserDesc>
      <TextHeader1 > Welcome to UserDetails page.</TextHeader1>
      <TextHeader2> Chinsurah</TextHeader2>
      </NavUserDesc>
      </NavUser>
      <UserInfoDiv>
      <NavUserDescLeft>
      <TextHeader3> User Information </TextHeader3>
      <FormControl>
        <RowDiv>
              <UserLabel> User Name  </UserLabel>
         </RowDiv>
                <RowDiv><InputTextBox/></RowDiv>
           <RowDiv>
                <UserLabel> Phone No  </UserLabel>
           </RowDiv>
               <RowDiv><InputTextBox/></RowDiv>
            <RowDiv>
                 <UserLabel> Email id  </UserLabel>
            </RowDiv>
               <RowDiv><InputTextBox/></RowDiv>
            <RowDiv>
                     <UserLabel> City  </UserLabel>
            </RowDiv>
                <RowDiv><InputTextBox/></RowDiv>
            <RowDiv>   
                <UserLabel> Flat No  </UserLabel>
            </RowDiv>
                <RowDiv><InputTextBox/></RowDiv>
             <RowDiv>   
                 <UserLabel>PIN Code  </UserLabel>
              </RowDiv>
             <RowDiv><InputTextBox/></RowDiv>
             <RowDiv>
                <button type="button" class="btn btn-primary">Edit/Modify</button>
             </RowDiv>
            
       </FormControl>
 
    </NavUserDescLeft>
    <NavUserDescRight>
    <TextHeader3> Booking History </TextHeader3>
    <TextHeader4> Upcoming Booking </TextHeader4>
    <UserInfoDiv>
    
    </UserInfoDiv>
   <table className='table table-primary table-bordered'>
       <thead>
         <tr>
          <th> Booking Id ..</th>
          <th> Booking Desc</th>
          <th> Booking Date</th>
          <th> Booking Slot</th>
          <th> Booking Status</th>
          <th> Comments</th>
         </tr>
       </thead>

       <tbody>
          {
            UpcomingBookingData.map((item,i)=>
            <tr key={i}>
              <td>{item.id}</td>
              <td>{item.desc}</td>
              <td>{item.date}</td>
              <td>{item.time}</td>
              <td>{item.status}</td>
              <td>{item.comments}</td>
              <td><button type="button" class="btn btn-primary">Re-Schedule</button></td>
            </tr>)
          }
        </tbody>
      </table>


    <UpcomingBookingDiv/>
    <TextHeader4> Booking History </TextHeader4>
    
   <table className='table table-primary table-bordered'>
   <thead>
     <tr>
      <th> Booking Id ..</th>
      <th> Booking Desc</th>
      <th> Booking Date</th>
      <th> Booking Slot</th>
      <th> Booking Status</th>
      <th> Comments</th>
     </tr>
   </thead>

   <tbody>
      {
        BookingHistoryData.map((item,i)=>
        <tr key={i}>
          <td>{item.id}</td>
          <td>{item.desc}</td>
          <td>{item.date}</td>
          <td>{item.time}</td>
          <td>{item.status}</td>
          <td>{item.comments}</td>
          </tr>)
      }
    </tbody>
  </table>

    </NavUserDescRight>
  
      </UserInfoDiv>
     

    </>
  
  )
}


