import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "./hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();
    //console.log("******* Ln 7 User Role = " + useAuth().name);
    return (
       
      //auth?.roles?.find(role => allowedRoles?.includes(role))
            //? <Outlet />
            //: auth?.user
                //? <Navigate to="/unauthorized" state={{ from: location }} replace />
               // : <Navigate to="/LoginForm" state={{ from: location }} replace /> 
                
        auth?.user
            ? <Outlet />
            : auth?.user
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/LoginForm" state={{ from: location }} replace />
    );
}

export default RequireAuth;