import './App.css';

import Home from "./Home";
import {Aboutus} from "./Aboutus";
import {Order} from "./Order";
import {Contact} from "./Contact";
import {Users} from './Users';
import { OrderDetails } from './OrderDetails';
import { UserDetails } from './UserDetails';
import { Support } from './Support';
import NavBar from './NavBar';

import Sidebar from './components/Sidebar';
import { BrowserRouter, Route, Routes, Switch } from "react-router-dom";
import { Technicians } from './Technicians';
//import { AddNewTask } from './components/AddNewTask';
import { AddNewTask } from './components/AddNewTask';
import AddNewTaskModalCustom from './components/AddNewTaskModalCustom';
import {LoginForm} from './components/Forms/LoginForm/LoginForm';
import {RegisterForm} from './components/Forms/RegisterForm/RegisterForm';
import React, { useState } from 'react';

import Missing from '../src/components/Missing';
import Unauthorized from '../src/components/Unauthorized';
import Lounge from '../src/components/Lounge';
import LinkPage from '../src/components/LinkPage';
import RequireAuth from '../src/components/RequireAuth';
import Layout from '../src/components/Layout';
import Login2 from '../src/components/Login2';
import Editor from '../src/components/Editor';
import Admin from '../src/components/Admin';
import {HomeAdmin} from '../src/HomeAdmin'
import {HomeUser} from '../src/HomeUser'

import {HomeDesigner} from '../src/HomeDesigner';
import {HomeMarketing} from '../src/HomeMarketing';

const ROLES = {
  'User': 'User',
  'Editor': 'Editor',
  'Admin': 'Admin',
  'Designer': 'Designer',
  'Marketing': 'Marketing'
}

/* run command : npm start */

const App=()=>{

 //const LoggdIn =  window.localStorage.getItem("token");
 const LoggdIn = window.localStorage.removeItem("isLoggedIn");
  return (
    <Routes>
    <Route path="/" element={<Layout />}>
      {/* public routes */}
      <Route path="/" element={<Home />} />
      <Route path="LoginForm" element={<LoginForm />} />
   
     
   
      
       <Route path="linkpage" element={<LinkPage />} />
      <Route path="unauthorized" element={<Unauthorized />} />

      {/* we want to protect these routes */}
       <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
       <Route path="HomeUser" element={<HomeUser/>} />
       </Route>

     <Route element={<RequireAuth allowedRoles={[ROLES.Designer]} />}>
       <Route path="HomeDesigner" element={<HomeDesigner/>} />
       <Route path="Aboutus" element={<Aboutus/>} />
         <Route path="Contact" element={<Contact/>} />
         <Route path="Support" element={<Support/>} />
    
        </Route>
        <Route element={<RequireAuth allowedRoles={[ROLES.Marketing]}/>}>
       <Route path="HomeMarketing" element={<HomeMarketing/>} />
       <Route path="Aboutus" element={<Aboutus/>} />
         <Route path="Contact" element={<Contact/>} />
         <Route path="Support" element={<Support/>} />
    
        </Route>
      <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
         <Route path="HomeAdmin" element={<HomeAdmin/>} />
         <Route path="AddNewTaskModalCustom" element={<AddNewTaskModalCustom/>} />
         <Route path="RegisterForm" element={<RegisterForm />} />
         <Route path="Users" element={<Users/>} />
         <Route path="Aboutus" element={<Aboutus/>} />
         <Route path="Contact" element={<Contact/>} />
         <Route path="Support" element={<Support/>} />
          
     
      </Route>

      {/* catch all */}
      <Route path="*" element={<Missing />} />
    </Route>
  </Routes>
);
 };
export default App;
