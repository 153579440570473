import {React,  useState, useEffect} from 'react'
import DataTable, { createTheme } from 'react-data-table-component';
import axios from 'axios';
import ModalCustom from './ModalCustom'
import useAuth from "./hooks/useAuth";
import ModalCustomMarketing from './ModalCustomMarketing'

createTheme('solarized', {
  text: {
    primary: '#268bd2',
    secondary: '#2aa198',
  },
  background: {
    default: '#002b36',
  },
  context: {
    background: '#cb4b16',
    text: '#FFFFFF',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
}, 'dark');


function TasksDesignerRole({userName}) {
   
  const { auth } = useAuth(); 
    const [taskOwner, SetTaskOwner] = useState('');
    const [Tasks, SetTasks] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const[printOrderId, SetprintOrderId] = useState();

    const showModal = () => {
      setIsOpen(true);
    };
      
    const hideModal = () => {
      setIsOpen(false);
    };

    const getTasks=async()=>{
      SetTaskOwner('Designer'); // Hard Coded Get logged In User from AuthUser 
     try{
        //const response = await axios.get("http://localhost:8080/loginAPI/tasks/Debojit");
        const response = await axios.get("https://printsundar.online/loginAPI/PrintOrders/"+auth.user+"/");
        //const response = await axios.get("tasks/Debojit/");
        SetTasks(response.data);
     }catch(error)
     {
         console.log(error);
         alert('Please check network connections..'+ error);
     }
  
    }
    
    const handleEditClick=async(evt, param)=>{
      const taskId = param; 
      //console.log("recived Task Id: "+ taskId);
      SetprintOrderId(taskId);
      showModal();
    }

    // handle Delete request... starts below

    let handleCloseClick = async (evt, param) => {
      //e.preventDefault();
      alert("Do you want to close the task?");
      SetprintOrderId(param);
      showModal();
    }
  
    
    
    // Handle Delete request ends above....
    const column =[
  
        {
          name: "printOrderId",
          selector: row=>row.printOrderId,
          sortable: true,
          wrap: true,
          FontFace: 50,
          maxWidth: "80px"
        },
        {
          name: "Booking Type",
          selector: row=>row.bookingType,
          FontFace: 50,
          sortable: true,
          wrap: true,
          maxWidth: "200px"
        },
        {
          name: "Order Name",
          fontSize: 90,
          fontWeight:300,
          selector: row=>row.orderName,
          sortable: true,
          wrap: true,
          maxWidth: "130px"
        },
        {
          name: "Card Type",
          fontSize: 90,
          fontWeight:300,
          selector: row=>row.cardType,
          sortable: true,
          wrap: true,
          maxWidth: "150px"
        },
        {
          name: "Status",
          fontSize: 90,
          fontWeight:300,
          selector: row=>row.taskStatus,
          sortable: true,
          wrap: true,
          maxWidth: "150px"
        },
        {
          name: "Comments",
          fontSize: 90,
          fontWeight:300,
          selector: row=>row.comments,
          sortable: true,
          wrap: true,
          maxWidth: "350px",
          
        },
        {
            name: "Action Edit",
            cell: row=> <button type="button" className="btn btn-primary" onClick={event=>handleEditClick(event, row.printOrderId)}>Edit</button>,
            //cell: row=> <button type="button" className="btn btn-primary" onClick={showModal}>Edit</button>,
            maxWidth: "100px",
          },
          {
            name: "Action Close",
            cell: row=> <button type="button" className="btn btn-info" onClick={event=>handleCloseClick(event, row.printOrderId)}>Close</button>,
            maxWidth: "150px",
          }
         
      
        ]
        
        useEffect(()=>{
          getTasks();
      
        },[taskOwner]);

  return (
   <>
      <DataTable title = "Task List" columns={column} 
    data= {Tasks} 
    theme="solarized"
    pagination 
    selectableRows
    selectableRowsHighlight
    highlightOnHover
    subHeader
    subHeaderComponent= {<input type="text" placeholder='Search here..' className='w-25 form-control'/>}
    subHeaderAlign='left'
    customStyles={{
      subHeader: {
        style: { fontWeight: "bold", color: "red", fontSize: "36px" }
      }
    }}
    />
    <ModalCustomMarketing isOpen={isOpen} hideModal={hideModal} printOrderId={printOrderId}/>
    </>
        
  );
}

export default TasksDesignerRole