import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

export const SidebarData = [
    {
      title: "Home",
      path:"../HomeAdmin",
      icon: <AiIcons.AiFillHome />,
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
    }, {
      title: "Users",
      path:"../Users",
      icon: <AiIcons.AiFillCustomerService />,
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
    },
    {
      title: "Orders",
      path: "../Order",
      icon: <IoIcons.IoIosPaper />,
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
    
    },
    {
      title: "Technicians",
      path: "/Technicians",
      icon: <FaIcons.FaEnvelopeOpenText />,
    
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
         
    },
    {
      title: "Support",
      path: "/support",
      icon: <IoIcons.IoMdHelpCircle />,
    },
    {
      title: "Contact",
      path: "../Contact",
      icon: <FaIcons.FaPhone />,
    },
    {   
      title: "About Us",
      path: "../Aboutus",
      icon: <AiIcons.AiFillApi />,
      iconClosed: <RiIcons.Ri4KFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
         
    },
  ];