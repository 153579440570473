import { useState, useContext,useEffect, useRef } from 'react'
//import { AuthContext } from '../../context/AuthContext';
// import styles of this component
import styles from '../Forms.module.css'

// import other component
import FormInput from '../FormInput/FormInput'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {Routes, Route } from 'react-router-dom';

// import other pkgs
import { Container, Form, Button } from 'react-bootstrap'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import PropTypes from 'prop-types'
import useAuth from '../../hooks/useAuth';
// import utils 
import { getStorage, setUserId, updateStorage } from '../../utils/storage'
import { number } from 'yup/lib/locale';
import Spinner from 'react-bootstrap/Spinner';


const baseUrl = 'https://printsundar.online/loginAPI/taskUserByName/';



export const LoginForm = (  ) => {
 
  const [open, setOpen] = useState(false);
  const { setAuth } = useAuth();
    
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');

    
    const [submit, setSubmit] = useState(false)
    const [triggerService, setTriggerService] = useState(false);
    const [finalURL, setfinalURL] = useState('');
    
    useEffect(() => {
        //userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const [fetchingData, setFetchingDataState] = useState({
        userIdService:'',
        userNameService: '',
        userPwdService: '',
        userPhService:'',
        userTokenService:'',
        userRoleService:''
        });
    
    // Declaring state variables ends above ...
      const [data, setData] = useState({
        username: '',
        password: '',
        userNameService: '',
        userPwdService: '',
        loginFinalURL:'',
        check_textInputChange: false,
        secureTextEntry: true,
        isValidUser: true,
        isValidPassword: true,
        });
        
        const  resetForData =()=>{

            setUser('');
            setPwd('');

        }
       /* const validateCredentials = () => {
            // if used in more components, this should be in context 
            // axios to /logout endpoint 
            console.log("Ln 81 Inside validateCredentials() Entered User name =" + user + " Pwd "+ pwd);
            console.log("Ln 82 Inside validateCredentials() Servcie Data User name =" + fetchingData.userNameService + " Pwd "+ fetchingData.password);
           if(user != fetchingData.userNameService || pwd != fetchingData.password)
             {
                alert("Invalid User Credentials...");
                resetForData();
                return false;

             }
             
          }
          */

    //const { signIn } = useContext(AuthContext);

    function sleep(delay) {
        var start = new Date().getTime();
        while (new Date().getTime() < start + delay);
      }

     const formik = useFormik({
        initialValues: {
            user: '',
            phone: '',
            pwd: '',
        },
        validationSchema: object({
            user: string().required('please enter your username')
                .max(15, 'your username must be 15 characters or less')
                .min(4, 'your username must be 4 characters or more'),
            phone:string().required('please enter your phone')
                .max(10, 'your phone number must be 10 digits')
                .min(10, 'your phone number must be 10 digits'),
            pwd: string().required('please enter your password')
                .min(8, 'your password must be 8 characters or more')
                .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, 'invalid password'),
        }),
        
               
        onSubmit: ({ user, phone, pwd }, { setFieldError }) => {
            //e.preventDefault();
             setAuth({});
             setOpen(false);
              //console.log("Username phone no and password = " + user+"--"+ phone +"//" + pwd);
              const loginFinalURL =  baseUrl.concat(user);
              if(setTriggerService.length > 0)
                 {
                    setfinalURL(loginFinalURL);
                    //setTriggerService(true);

                    try {
                        console.log("Start of onSubmit() Ln 138 final URL= " + loginFinalURL); 
                        fetch(loginFinalURL).then(async response=>{
                            console.log("Response.status = " + response.status); 
                            if(response === null)
                            {
                                console.log("Response is null.. "); 
                                alert("Server is down. Please try after sometime...");
                                return;
                            }
                            ///console.log("Start of onSubmit() Ln 140 Response Status = " + response.status +"response Json= "+response.json.toString()); 
                            if (response.status >= 200 && response.status < 300)
                              {
                               
                               // Check data here for validity and return...
                                response.json().then(data=>{
                                  setFetchingDataState({
                                      ...fetchingData,
                                      userIdService: data.userId,
                                      userNameService: data.userName,
                                      userPwdService: data.passWord,
                                      userPhService: data.phoneNo,
                                      userTokenService: data.userToken,
                                      userRoleService: data.userRole
                                    })
                                    //await timeout(2000);
                                    setOpen(false);
                                    //sleep(2000);// Delay for 2 seconds..
                                    console.log("Fetching Data  fetchingData.userNameService.length = "+ data.useName);

                                   /* if(data.userName ==="Invalid User")
                                    {
                                        alert("Invalid User Credentials..");
                                        resetForData();
                                        return false;

                                    }
                                    */
                                    
                               //console.log("Ln 141 Inside : fetchingData = User Id= " + fetchingData.userIdService+ " User Name= " + fetchingData.userNameService +"User password ="+  fetchingData.userPwdService );
                               // Check if user is valid or not???    
                               //console.log(JSON.stringify(response?.data));
                               const accessToken = data.accessTokenn;
                               const roles = data.userRole;
                                //console.log(" @@@@@@ Ln 86 Inside useEffect() : User ="+  user +" PWD= "+ pwd+" Roles"+roles+" accessToken"+ accessToken);
                                    setAuth({ user, pwd, roles, accessToken });
                                    window.localStorage.setItem("token", data);
                                    window.localStorage.setItem("isLoggedIn", true);
                                    window.localStorage.setItem("UserName", user);
                                    setUser('');
                                    setPwd('');
                                    if(data.userRole==='Admin')
                                    {
                                        //console.log("Calling HomeAdmin from Ln 180");
                                        navigate('/HomeAdmin');
                                    }
                                       
                                    if(data.userRole==='User')
                                        navigate('/HomeUser');

                                        if(data.userRole==='Designer')
                                        navigate('/HomeDesigner');
                                        if(data.userRole==='Marketing')
                                        navigate('/HomeMarketing');
                              
                               //navigate(from, { replace: true });
                                   
                              });
                            }
                            else{

                                alert("Invalid Login Credentials...");
                                return;
                            }
                        });
                   
                      
                    } catch (err) {
                        if (!err?.response) {
                            //setErrMsg('No Server Response');
                            alert('No Server Response');
                        } else if (err.response?.status === 400) {
                            //setErrMsg('Missing Username or Password');
                            alert('Missing Username or Password');
                        } else if (err.response?.status === 401) {
                            //setErrMsg('Unauthorized');
                            console.log("Unauthorized User");
                            alert('Unauthorized User');
                        } else {
                            //setErrMsg('Login Failed');
                            alert('Login Failed');
                        }
                        errRef.current.focus();
                    }
                 }
                 else{
                    //setFieldError('username', 'Enter valid Username or Password');
                    alert('Enter valid Username or Password');
                 }

             //const users = getStorage('users')
            //const myVerifyUser = users && users.find(user => user.username === username)
            /*if (users && myVerifyUser) {
                if (myVerifyUser.email === email && myVerifyUser.password === password)
                    login(myVerifyUser)
                else if (myVerifyUser.email !== email)
                    setFieldError('email', `your email isn't true`)
                else 
                    setFieldError('password', `your password isn't correct`)
            } else
                setFieldError('username', 'your username not found')
                */
        }
    })

    const login = (myVerifyUser) => {
       
        //const users = getStorage('users')
        //updateStorage(users, myVerifyUser, true)
        //setUserId(myVerifyUser.id)

        //onLogin()
    }

    const handleRegister=()=>{

        alert("User registration can be done by");
    }


    /*
    const handleSubmit = async (e) => {
        e.preventDefault();
        setOpen(true);
        try {
            console.log("Start of useEffect() Ln 51 " + finalURL); 
            fetch(finalURL).then(async response=>{
                if (response.status >= 200 && response.status < 300)
                  {
                   // Check data here for validity and return...
                    response.json().then(data=>{
                      setFetchingDataState({
                          ...fetchingData,
                          userIdService: data.userId,
                          userNameService: data.userName,
                          userPwdService: data.passWord,
                          userPhService: data.phoneNo,
                          userTokenService: data.userToken,
                          userRoleService: data.userRole
                        })
       
                   console.log("Ln 65 Inside useEffect() : fetchingData = User Id= " + data.userId+ " User Name= " + data.userName + "User password =  "+ "User password ="+  data.passWord +" User phone No ="+ data.phoneNo +" User password =" +data.password +"User Role ="+data.userRole);
                   // Check if user is valid or not???    
                   console.log(JSON.stringify(response?.data));
                   const accessToken = data.accessTokenn;
                   const roles = data.roles;
                   console.log(" @@@@@@ Ln 86 Inside useEffect() : User ="+  user +" PWD= "+ pwd+" Roles"+roles+" accessToken"+ accessToken);
                   setAuth({ user, pwd, roles, accessToken });
                   setUser('');
                   setPwd('');
                   navigate(from, { replace: true });
                       
                  });
                }
            });
       
            console.log(JSON.stringify(response?.data));
            //console.log(JSON.stringify(response));
            const accessToken = response?.data?.accessToken;
            const roles = response?.data?.roles;
            setAuth({ user, pwd, roles, accessToken });
            setUser('');
            setPwd('');
            navigate(from, { replace: true });
            
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }
    */

    return (
        <div className='container-fluid bg-white text-dark height=100px'>
           <Container fluid className={`${styles.container} d-flex justify-content-center align-items-center px-25`}>
            <Form noValidate className={styles.form_signIn} onSubmit={formik.handleSubmit}>
                <h2 className=''>Login</h2>

                <FormInput
                    className="mb-4 mt-5"
                    name="user"
                    controlId="username-input"
                    text="Username"
                    placeholder="Enter your Username"
                    errMsg={formik.errors.user || ''}
                    successMsg="done"
                    invalid={submit && formik.errors.user ? true : false}
                    valid={submit && !formik.errors.user ? true : false}
                    {...formik.getFieldProps('user')}
                />

                <FormInput  
                    className="mb-4"
                    name="phone"
                    controlId="phone"
                    type="number"
                    text="Enter Phone"
                    placeholder="Enter your phone"
                    errMsg={formik.errors.phone || ''}
                    successMsg="done"
                    invalid={submit && formik.errors.phone ? true : false}
                    valid={submit && !formik.errors.phone ? true : false}
                    {...formik.getFieldProps('phone')}
                />

                <FormInput
                    className="mb-4"
                    name="pwd"
                    controlId="password-input"
                    text="Password"
                    placeholder="Enter your Password"
                    type="password"
                    errMsg={formik.errors.pwd || ''}
                    successMsg="done"
                    invalid={submit && formik.errors.pwd ? true : false}
                    valid={submit && !formik.errors.pwd ? true : false}
                    {...formik.getFieldProps('pwd')}
                />

                <Button 
                    onClick={() => handleRegister('register')}
                    className='shadow-none mt-4 p-0'
                    type="button"
                    variant="">
                    you dont' have any account ?
                </Button>

                <Button 
                    className={`${styles["submit-btn"]} w-100`} 
                    onClick={() => setSubmit(true)}
                    disabled={submit && !formik.isValid ? true : false}
                    variant="primary" 
                    type="submit">
                    Login
                </Button>
            </Form>
        </Container>
      
        </div>
    )
}

/*
// validate the component
LoginForm.propTypes = {
    onRegister: PropTypes.func.isRequired, 
    onLogin: PropTypes.func.isRequired,
}
*/

