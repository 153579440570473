import {React, useState, useEffect  } from 'react'
import Web from '../src/img/StarUp.PNG';
import styled from 'styled-components';
import {Routes, Route, useNavigate} from 'react-router-dom';
import CountriesTable from './components/CountriesTable';
import Tasks from './components/Tasks';
import AddNewTask from './components/AddNewTask';
import CustomtDialog from './components/CustomDialog';
import {LoginForm} from './components/Forms/LoginForm/LoginForm';
//import ModalDlg from './components/ModalDlg';
import SidebarNonAdmin from './components/SidebarNonAdmin';
import { Link } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "./components/context/AuthProvider";
import Home from "./Home";


import AddNewTaskModalCustom from './components/AddNewTaskModalCustom';

import TasksMarketingRole from './components/TasksMarketingRole';

import {Container, Nav,  Navbar, NavDropdown, Row, Col, Card } from 'react-bootstrap';
import './Home.css';


const ExtraPadding = styled.div`
margin-left: 20px;
`;
const ExtraleftPadding = styled.div`
margin-left: 340px;
`;
const ExtraBottomPad = styled.div`
margin-bototom: 20px;
`;
const DropDownStyle = styled.div`
  margin: 100;
  width: 250;
`;
const TaskButton = styled.button`
margin-left: 10px;
background:green;
font-size: 1em;
  margin: 1em;
  width: 100px;
  height: 40px;
  padding: 0.25em 1em;
  border: 2px solid white;
  border-radius: 3px;

`;
const NavUser = styled.div`
  background: #4d9dff;
  margin-top: 1px;
  margin-bottom: 15px;
  margin-left: 201px;
  margin-right: 100px;
  height: 50px;
  width:86%;
  border:solid 1px #0000ff
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const LabelUser = styled.span`
  margin-left: 10px;
`;
const NavTop = styled.div`
  background: #0A1063;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const InputTextBox = styled.input.attrs(props => ({
  // we can define static props
  type: "text",
  placeholder: "Search Tasks",
  // or we can define dynamic ones
  size: 40 || "0.1em",
  
}))`
  color: black;
  font-size: 1em;
  border: 1px solid black;
  border-radius: 2px;

  /* here we use the dynamically computed prop */
  margin:  5px;
  padding: 5px;
  margin-left: 20px;
  margin-right: 15px;
  margin-bottom: 5px;
  align-items:left;
`;

export const HomeMarketing = () => {
  
  console.log("Inside HomeMarketing() scrren  Ln 87");

  const [userInfo, setUserInfo] = useState([]);  // setting user info 
  //const [modal, setModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const showModal = () => {
    //console.log("Called showModal() Ln 103");
    setIsOpen(true);
  };
    
  const hideModal = () => {
    setIsOpen(false);
  };

  const handleLogout = async () => {
    // if used in more components, this should be in context 
    // axios to /logout endpoint 
    //console.log("Inside handleLogout_ of HomeAdmin Class Ln 114");
    setAuth({});
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("isLoggedIn");
    navigate('/');
  }
const handleLogIn=()=>{
 
  navigate('/LoginForm');
}
  const navigateToAddNewTask = (props) => {
    // 👇️ navigate to /contacts
    //navigate('/AddNewTask');
    showModal();
  };
  // Getting User Info during loading of the Page
    
  useEffect(() => {
    const getcategory = async () => {
      const res = await fetch("https://printsundar.online/loginAPI/getCRMUsers");
      const getdata = await res.json();
      setUserInfo(getdata);
      // console.log(getdata);
    };

    getcategory();
  },[]);
  
  const onClickProductSelected=(name, email, phone)=>{
   console.log("Button Clicked.." , {name} , {email}, {phone});
   }
   return (
    <>
    <div>
      <SidebarNonAdmin/>
       <NavUser>
      <NavTop>
      <h2
            style={{ textAlign: "left", 
                     marginLeft: "5px", 
                     marginright: "25px",
                     color: "white" }}
          >
            My Marketing Tasks
          </h2>
          <ExtraPadding/>
          <ExtraleftPadding/>
          <button type="button" class="btn btn-info" onClick={() => 
          handleLogout()}>Logout</button>
                           
      </NavTop>
      < TasksMarketingRole/>  
      
    </NavUser>
    </div>
    <AddNewTaskModalCustom isOpen={isOpen} hideModal={hideModal}/>
    </>
    )
 }
