import React, { useState, useEffect } from 'react'
import Modal from "react-bootstrap/Modal";
import { Container, Form, Button, Row , Col } from 'react-bootstrap'
import toastr from 'cogo-toast';
import validator from 'validator'
import axios from 'axios';
import useAuth from "./hooks/useAuth";

const ModalCustom =({isOpen, hideModal, taskId}) =>{
 
  const [taskdesc, setTaskDesc] = useState('');
  const [Phone, setPhoneNo] = useState('');
  const [email, setEmailId] = useState('');
  const [pCode, setPropertyCode] = useState('');
  const [interested, setInterestedIn] = useState('');
  const [created, setCreatedBy] = useState('');
  const [assigned, setAssignedTo] = useState('');
  const [targetDate, setDeadline] = useState('');
  const [taskComments, setComments] = useState('');
  const [oldComments, setOldComments] = useState('');
  const [prio, setPriority] = useState('');
  const [message, setMessage] = useState('');
  const [taskStatus, setTaskStatus] = useState('');
  
  const [loggedInUser, setloggedInUser] = useState(''); 
  const { auth } = useAuth();
  //oldComments



  const [taskData, setTaskData] = useState({
            taskCreationDate: "",
            taskAge: 0,
            attachments: "",
            property_Code: "",
            interested_In: "",
            taskId: taskId,
            taskOwner: "",
            taskStatus: "",
            email_Id: "",
            taskCreatedBy: "",
            taskDeadline: "",
            comments: "",
            oldComments:"",
            taskPriority: "",
            taskDesc: "",
            phone_No: ""
  })

  useEffect((taskId)=>{
  
    fetchServiceData();
   
    //setloggedInUser(auth.user);
  },[taskId]);

  const fetchServiceData=async()=>{
 
    //let errorMsg = '';
    //console.log("Task Id Ln 35 = " + taskId)
    const baseURL = "/taskById";
    const finalUrl = baseURL.toString()+"/"+taskId.toString();
    //console.log("Final URL=" + finalUrl);
      try{
      const response = await axios.get(finalUrl);
      //console.log("Line 60 Modal Custom Databse Response= " + response.data)
      setTaskData(response.data); // Save seelcted task data into 

        setTaskDesc(response.data.taskDesc)
        setPhoneNo(response.data.phone_No)
        setEmailId(response.data.email_Id)
        setPropertyCode(response.data.property_Code)
        setInterestedIn(response.data.interested_In)
        setCreatedBy(response.data.taskCreatedBy)
        setAssignedTo(response.data.taskOwner)
        setDeadline(response.data.taskDeadline)
        setTaskStatus(response.data.taskStatus)
        //setComments(response.data.comments)
       
        const final = response.data.oldComments +": \n " + response.data.comments;
        setOldComments(final);
        setPriority( response.data.taskPriority)
               
      //console.log("Data set inside Task.js = " + rowData.taskdesc +"--"+ rowData.PhoneNo+"--"+ rowData.emailId +"--"+ rowData.propertyCode +"--"+ rowData.interestedIn +"--"+ rowData.createdBy +"--"+ rowData.assignedTo +"--"+ rowData.deadline +"--"+ rowData.comments +"--"+ rowData.priority);
    
   }catch(error)
   {
       console.log(error);
       alert('Please check network connections..'+ error);
   }
 
   }

    const handleTaskDesc=async(evt, param)=>{
   
    setTaskDesc(param);
    
   }

   const handleTaskComments=async(evt, param)=>{
              setComments(param);
    }

   /* const handleOldTaskComments=async(evt, param)=>{
      setOldTaskComments(param);
    }
    */

    const handleTaskPhone=async(evt, param)=>{
     
      setPhoneNo(param);
   }
    const handleTaskEmailId=async(evt, param)=>{
     
      setEmailId(param);
    }
    const handleTaskpCode=async(evt, param)=>{
    
    setPropertyCode(param);
    }
    const handleTaskInterested=async(evt, param)=>{
      
    setInterestedIn(param);
    }
    const handleTaskAssigned=async(evt, param)=>{
     
          setAssignedTo(param); 
    } 
    //handleTaskStatus
    const handleTaskStatus=async(evt, param)=>{
      
      console.log("Selected Task Status= " + param);
      setTaskStatus(param);
      } 
    const handleTasktTargetDate=async(evt, param)=>{
      
      setDeadline(param);
      }
      const handleTaskCreatedBy=async(evt, param)=>{
       
        setCreatedBy(param);
        }

    // Code to Save data by rest service call starts below....
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

    const checkClientValidation =()=>{

      if(taskdesc.length === 0)
      {
          alert("Please Enter task description..");
          return false;
      }
      if(Phone.length !== 10 )
      {
          alert("Please Enter a Valid Phone Number..");
          return false;
      }
      if(email.length === 0)
      {
          alert("Please Enter email Id..");
          return false;
      }
      if(pCode.length === 0)
      {
          alert("Please Enter propertyCode ..");
          return false;
      }
      if(interested.length === 0)
      {
          alert("Please Enter interestedIn..");
          return false;
      }
       if(!validator.isDate(targetDate)){
        alert("Please select Task Completion Date..");
          return false;

      }
      return true;

    }    

    let handleSubmit = async (e) => {
      e.preventDefault();
      let errorMsg = '';
      //console.log("Task Comments = " + taskComments);
     if(taskComments == '')
    {
      errorMsg = 'Please fill task update as comments.';
      console.log("Auth user Ln 58  ="  + auth.user); 
      alert(errorMsg);
      return;
    }
    else
    {
      try {
        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
        const finalComments = date.toString() +"/ By "+auth.user+": " + taskComments ;
        console.log("Task Status and Created By" + taskStatus + ""+ created);
        let body ={};
        if(taskStatus ==='Close')
        {
           body =
          {
           taskId: taskData.taskId,
           taskCreationDate: taskData.taskCreationDate,
           taskDesc: taskdesc,
           phone_No: Phone,
           interested_In: interested,
           taskStatus: taskStatus,
           taskCreatedBy: created,
           comments: finalComments,
           oldComments: oldComments,
           property_Code: pCode,
           email_Id: email,
           taskAge: 0,
           attachments: taskData.attachments,
           taskPriority: taskData.taskPriority,
           taskOwner: created,
           taskDeadline: targetDate
         }; 

        }
        else
        {

           body =
          {
           taskId: taskData.taskId,
           taskCreationDate: taskData.taskCreationDate,
           taskDesc: taskdesc,
           phone_No: Phone,
           interested_In: interested,
           taskStatus: taskStatus,
           taskCreatedBy: created,
           comments: finalComments,
           oldComments: oldComments,
           property_Code: pCode,
           email_Id: email,
           taskAge: 0,
           attachments: taskData.attachments,
           taskPriority: taskData.taskPriority,
           taskOwner: assigned,
           taskDeadline: targetDate
         }; 

        }
        //console.log("Final Comments Ln 188 = " + finalComments);
        /*
        const body =
       {
        taskId: 143,
        taskCreationDate: "2022-10-15T08:36:02.259Z",
        taskDesc: "Hello taskdes Barun123",
        phone_No: "9433510469",
        interested_In: "Banglow Villa2",
        taskStatus: "High",
        taskCreatedBy: "Debojit",
        comments: "Urgent Requirement--Barun2",
        property_Code: "P0012A123",
        email_Id: "barun@gmail.com",
        taskAge: 0,
        attachments: "No Attachement",
        taskPriority: "High",
        taskOwner: "Bibhas",
        taskDeadline: "2022-10-30"
      }; 
      */
      /*
       const body =
       {
        taskId: taskData.taskId,
        taskCreationDate: taskData.taskCreationDate,
        taskDesc: {taskdesc},
        phone_No: {Phone},
        interested_In: {interested},
        taskStatus: taskData.taskStatus,
        taskCreatedBy: {created},
        comments:  {comments},
        property_Code: {pCode},
        email_Id: {email},
        taskAge: 0,
        attachments: taskData.attachments,
        taskPriority: taskData.taskPriority,
        taskOwner: {assigned},
        taskDeadline: {targetDate}
    }; 
    */
   
       
       axios.put("/updateTask",
          body  
         )
        .then(response => {  
          setMessage("Task Updated successfully");
          toastr.success('Task Updated successfully...', {position : 'top-right', heading: 'Done'});    
          hideModal();
      })
        .catch(error => {
          console.log("Error to Update" + error);
          setMessage("Error occured to Update Task...");
          toastr.error('Error to Update task!', {position : 'top-right', heading: 'Failed'});
        });

      } catch (err) {
        console.log("*** Error to Update Task= "+ err);
      }
    }
      
    };
    
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // Code to Save data by rest service call ends above....
    return (
      <> <Modal 
      show={isOpen} 
      onHide={hideModal} 
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
     >
     <Modal.Header style={{backgroundColor: "skyBlue"}} closeButton>
      <Modal.Title id="contained-modal-title-vcenter" color="white">
       Details of Task Id: {taskId}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="show-grid">
   
             <Form >
      <Form.Group controlId="formBasicEmail">
        <Row >
            <Col >
            <Form.Label>Task Description</Form.Label>
            <Form.Control type="taskDescription" disabled="true" as="textarea" rows={2} 
            name="task"
            value={taskdesc}
            onChange={event=>handleTaskDesc(event, event.target.value)}
            />
            </Col>
           <Form.Group controlId="formBasicCheckbox">
        <Form.Check type="checkbox" disabled="true" label="High Priority"  value={prio} 
        onChange={e => setPriority(e.target.checkbox)}
        />
      </Form.Group>
    </Row>
      </Form.Group>
   <Row>
    <Col>
    <Form.Group className="mb-3" controlId="phoneNo">
        <Form.Label>Phone No</Form.Label>
        <Form.Control type="number" disabled="true" placeholder="PhoneNo"  name="PhoneNo" value={Phone}
        onChange={event=>handleTaskPhone(event, event.target.value)}
        />
      </Form.Group>
    </Col>
    <Col>
    <Form.Group className="mb-3" controlId="emailId">
        <Form.Label>EmailId</Form.Label>
        <Form.Control type="text" disabled="true" placeholder="emailId" name="emailId"  value={email} 
         onChange={event=>handleTaskEmailId(event, event.target.value)}
        />
      </Form.Group>
    </Col>
   </Row>
     
   <Row>
    <Col>
    <Form.Group className="mb-3" controlId="propertyCode">
        <Form.Label>Property Code</Form.Label>
        <Form.Control type="text" disabled="true" placeholder="PropertyCode" name="propertyCode"  value={pCode}  
        onChange={event=>handleTaskpCode(event, event.target.value)}
        />
      </Form.Group>
    </Col>
    <Col>
    <Form.Group className="mb-3" controlId="interestedIn">
        <Form.Label>Interested In</Form.Label>
        <Form.Control type="text" disabled="true" placeholder="interestedIn" name="interestedIn" value={interested}   
       onChange={event=>handleTaskInterested(event, event.target.value)}
        />
      </Form.Group>
    </Col>
   </Row>
   <Row>
    <Col>
    <Form.Group className="mb-3" controlId="selectCreatedBy">
    <Form.Label>Created By</Form.Label>
      <Form.Control as="select"  disabled="true"
      name="createdBy" 
      value={ created }
      onChange={event=>handleTaskCreatedBy(event, event.target.value)}
       >
        <option value="Atanu">Atanu</option>
        <option value="Arijit">Arijit</option>
        <option value="Debojit">Debojit</option>
        <option value="Bibhas">Bibhas</option>
      </Form.Control>
      </Form.Group>
    </Col>
    <Col>
    <Form.Group className="mb-3" controlId="selectAssignedTo">
    <Form.Label>Assigned To</Form.Label>
      <Form.Control as="select" disabled="true"
       name="assignedTo" 
       value={ assigned}
       onChange={event=>handleTaskAssigned(event, event.target.value)}
       >
        <option value="Atanu">Atanu</option>
        <option value="Arijit">Arijit</option>
        <option value="Debojit">Debojit</option>
        <option value="Bibhas">Bibhas</option>
      </Form.Control>
      </Form.Group>
    </Col>
   </Row>
     <Row>
        <Col>
        <Form.Label>Task Completion Date</Form.Label>
        <Form.Control
                type="date" disabled="true"
                name="deadline"
                placeholder="DateRange"
                value={targetDate}
                onChange={event=>handleTasktTargetDate(event, event.target.value)}
              />
        </Col>
        <Col>
    <Form.Group className="mb-3" controlId="selectTask Status">
    <Form.Label>Task Status</Form.Label>
      <Form.Control as="select"
       name="taskStatus" 
       value={ taskStatus}
       onChange={event=>handleTaskStatus(event, event.target.value)}
       >
        <option value="Open">Open</option>
        <option value="Pending">Pending</option>
        <option value="Close">Close</option>
        <option value="Progress">Progress</option>
      </Form.Control>
      </Form.Group>
    </Col>
        </Row>
        <Row>
        <Col>
      <Form.Group className="mb-3" controlId="oldComments">
        <Form.Label>Comments History</Form.Label>
        <Form.Control type="text" disabled="true" placeholder="oldComments"  as="textarea" rows={5} name="oldComments" value={oldComments} 
        />
      </Form.Group>
    
        </Col>
     </Row>
        <Row>
        <Col>
      <Form.Group className="mb-3" controlId="comments">
        <Form.Label>Comments</Form.Label>
        <Form.Control type="text" placeholder="Comments"  as="textarea" rows={2} name="comments" value={taskComments}   
        onChange={event=>handleTaskComments(event, event.target.value)}
        />
      </Form.Group>
    
        </Col>
     </Row>
      <div className="message">{message ? <p>{message}</p> : null}</div>
    </Form>
      
        </Modal.Body>
      <Modal.Footer>
        <Button color="primary" onClick={hideModal}>Cancel</Button>
        <Button color="dark" onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal></>
    )
  }

export default ModalCustom