import {React,  useState, useEffect} from 'react'
import DataTable, { createTheme } from 'react-data-table-component';
import axios from 'axios';
import toastr from 'cogo-toast';
import ModalCustomAdmin from './ModalCustomAdmin'

createTheme('solarized', {
  text: {
    primary: '#000000',
    secondary: '#2aa198',
    fontSize: 50,
    fontWeight: 300
  },
  background: {
    default: '#FFFFFF',
  },
  context: {
    background: '#cb4b16',
    text: '#FFFFFF',
  },
  divider: {
    default: '#073642',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
}, 'dark');

function Tasks() {

    const [Tasks, SetTasks] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    //const[taskId, SetTaskId] = useState(1);
    const[printOrderId, SetprintOrderId] = useState(1);
   

     const showModal = () => {
      setIsOpen(true);
    };
      
    const hideModal = () => {
      setIsOpen(false);
    };

    const getTasks=async()=>{
     try{
        //const response = await axios.get("http://localhost:8080/loginAPI/tasks");
        const response = await axios.get("https://printsundar.online/loginAPI/PrintOrders");
        SetTasks(response.data);
     }catch(error)
     {
         console.log(error);
         alert('Please check network connections..'+ error);
     }
  
    }
    
    const handleEditClick=async(evt, param)=>{
      console.log("Inside task.js recived Task Id: "+ param);
      SetprintOrderId(param);
      showModal();
    }

    // handle Delete request... starts below

    let handleDeleteClick = async (evt, param) => {
      //e.preventDefault();
      const rowId = param; 
      const baseURL = "https://printsundar.online/loginAPI/removePrintTask";
      
      try {
        let res = await fetch(`${baseURL}/${rowId}`, { method: "delete" }).then(async response => {
          const isJson = response.headers.get('content-type')?.includes('application/json');
          const data = isJson && await response.json();
          //console.log(res);
          // check for error response
          if (!response.ok) {
              // get error message from body or default to response status
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
          }
  
          toastr.success('Task deleted successfully!', {position : 'top-right', heading: 'Done'}); 
          getTasks();
      })}
      catch(error) 
      {
          //element.parentElement.innerHTML = `Error: ${error}`;
          console.error('There was an error!', error);
      };
    }
  
    
    
    // Handle Delete request ends above....
    const column =[
  
        {
          name: "printOrderId",
          selector: row=>row.printOrderId,
          sortable: true,
          wrap: true,
          fontSize: 90,
          fontWeight:300,
          maxWidth: "80px"
        },
        {
          name: "Booking Type",
          selector: row=>row.bookingType,
          fontSize: 90,
          fontWeight:300,
          sortable: true,
          wrap: true,
          maxWidth: "200px"
        },
        {
          name: "Order Name",
          fontSize: 90,
          fontWeight:300,
          selector: row=>row.orderName,
          sortable: true,
          wrap: true,
          maxWidth: "130px"
        },
        {
          name: "Card Type",
          fontSize: 90,
          fontWeight:300,
          selector: row=>row.cardType,
          sortable: true,
          wrap: true,
          maxWidth: "150px"
        },
        {
          name: "Status",
          fontSize: 90,
          fontWeight:300,
          selector: row=>row.taskStatus,
          sortable: true,
          wrap: true,
          maxWidth: "150px"
        },
        {
          name: "Owner",
          fontSize: 90,
          fontWeight:300,
          selector: row=>row.taskOwner,
          sortable: true,
          wrap: true,
          maxWidth: "150px"
        },
        {
          name: "Comments",
          fontSize: 90,
          fontWeight:300,
          selector: row=>row.comments,
          sortable: true,
          wrap: true,
          maxWidth: "350px",
          
        },
        {
            name: "Action Edit",
            cell: row=> <button type="button" className="btn btn-primary" onClick={event=>handleEditClick(event, row.printOrderId)}>Edit</button>,
            //cell: row=> <button type="button" className="btn btn-primary" onClick={showModal}>Edit</button>,
            maxWidth: "100px",
          },
          {
            name: "Action Delete",
            cell: row=> <button type="button" className="btn btn-danger" onClick={event=>handleDeleteClick(event, row.printOrderId)}>Delete</button>,
            maxWidth: "150px",
          }
         
      
        ]
        
        
        useEffect(()=>{
          getTasks();
      
        },[]);

  return (
   <>
      <DataTable title = "Print Orders" columns={column} 
    data= {Tasks} 
    theme="solarized"
    pagination 
    selectableRows
    selectableRowsHighlight
    highlightOnHover
    subHeader
    subHeaderComponent= {<input type="text" placeholder='Search here..' className='w-25 form-control'/>}
    subHeaderAlign='left'
    customStyles={{
      subHeader: {
        style: { fontWeight: "bold", color: "red", fontSize: "36px" }
      }
    }}
    />
    <ModalCustomAdmin isOpen={isOpen} hideModal={hideModal} printOrderId={printOrderId}/>
    </>
        
  );
}

export default Tasks