import React from 'react'
import styled from "styled-components";


const NavUser = styled.div`
  background: #9ca5b5;
  margin-top: 1px;
  width: (100hh-100);
  height: 100px;
  margin-bottom: 0.5px;
  display: flex;
  margin-left: 205px;
  justify-content: left;
  border-radius: 10px;
  align-items: center;
  border:solid 1px #0000ff;

`;
const TextHeader1 = styled.h1`
  font-size:  1.5em;
  text-align: center;
  justify-content: center;
  margin-left: 10px;
  color: blue;
   `;

const NavUserDesc = styled.div`
  
  flex-direction: column;
  justify-content: start;
  align-items: center;
  border-radius: 10px;
`;

const NavUserDescLeft = styled.form`
 flex-direction: column;
 height: 470px;
  width: 100hh-100;
  margin-left: 205px;
  margin-top:1px;
  background-color:#6eb5c4;
  border-radius: 5px;

  `;

export const Aboutus = () => {
  return (
    <>
    <NavUser>
       <NavUserDesc>
        <TextHeader1 > About Us </TextHeader1>
    </NavUserDesc>
    </NavUser>
     <NavUserDescLeft>
      
     </NavUserDescLeft>

  </>
    
  )
}
