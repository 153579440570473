import React from 'react'
import styled from "styled-components";
import {Routes, Route, useNavigate} from 'react-router-dom';

import {Table, Button} from 'react-bootstrap'

const OrderData =[

  {orderid:'001234',companyName:'Hero Honda', regNo:'WB16A9887',model:'Super Splender', workType: 'Basic', owner:'Barun Bairagi',phoneNo:'9433510469',bookingDate:'05/6/22', status:'pending'},
  {orderid:'0001235',companyName:'Hero Honda', regNo:'WB16B9812',model:'Splender+', workType: 'Full Service',owner:'Arun Kumar',phoneNo:'9433510469',bookingDate:'05/6/22',status:'pending'},
  {orderid:'0001236',companyName:'Suzuki', regNo:'WB16A9887', model:'Super Model', workType: 'Full Service',owner:'Barun Bairagi',phoneNo:'9433510469',bookingDate:'05/6/22',status:'pending'},
  {orderid:'0001237',companyName:'Honda Shine',regNo:'WB16A9887', model:'Xing', workType: 'Basic',owner:'Prasun Sengupta',phoneNo:'9433510469',bookingDate:'05/6/22',status:'pending'},
  {orderid:'0001238',companyName:'Bajaj', regNo:'WB16A9887', model:'Pulsar', workType: 'Basic',owner:'Hirak Sen',phoneNo:'9433510469',bookingDate:'05/6/22',status:'pending'},

]



export const Grid = styled.div`
  display: grid
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 50px 50px
  grid-gap: 5px
`;


const NavUser = styled.div`
  background: #4d9dff;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 205px;
  margin-right: 150px;
  height: 50px;
  width:80%;
  border:solid 1px #0000ff
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const LabelUser = styled.span`
  margin-left: 10px;
`;


export const Order = () => {

  const navigate = useNavigate();

  const navigateToOrderDetails = (props) => {
    // 👇️ navigate to /contacts
    navigate('/OrderDetails');
  };

  const onClickProductSelected=(orderid)=>{
   console.log("Button Clicked.." , {orderid});
   }
   
  return (
    <NavUser>
         <h1>Order Details</h1>
      <table className='table table-primary table-bordered'>
       <thead>
         <tr>
          <th> Orderid</th>
          <th> CompanyName</th>
          <th> RegNo</th>
          <th> Model</th>
          <th> WorkType</th>
          <th> Owner</th>
          <th> PhoneNo</th>
          <th> BookingDate</th>
          <th> Status</th>
          <th> Action</th>
         </tr>
       </thead>
        <tbody>
          {
            OrderData.map((item,i)=>
            <tr key={i}>
              <td>{item.orderid}</td>
              <td>{item.companyName}</td>
              <td>{item.regNo}</td>
              <td>{item.model}</td>
              <td>{item.workType}</td>
              <td>{item.owner}</td>
              <td>{item.phoneNo}</td>
              <td>{item.bookingDate}</td>
              <td>{item.status}</td>
              <td><button type="button" class="btn btn-primary" onClick={() => 
          navigateToOrderDetails(item.orderid)}>Edit/ Update</button></td>
            </tr>)
          }
        </tbody>
      </table>
    </NavUser>
  )
}

