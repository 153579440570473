import {React, useState, useEffect  } from 'react'
import Web from '../src/img/StarUp.PNG';
import styled from 'styled-components';
import {Routes, Route, useNavigate} from 'react-router-dom';
import CountriesTable from './components/CountriesTable';
import Tasks from './components/Tasks';
import CustomtDialog from './components/CustomDialog';
import {LoginForm} from './components/Forms/LoginForm/LoginForm';
import {HomeAdmin} from '../src/HomeAdmin';
import {HomeUser} from '../src/HomeUser';
//import ModalDlg from './components/ModalDlg';

import { Link } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "./components/context/AuthProvider";
import {Container, Nav,  Navbar, NavDropdown, Row, Col, Card } from 'react-bootstrap';
import { MDBFooter } from 'mdb-react-ui-kit';

import './Home.css';

const Home = () => {

  const [userInfo, setUserInfo] = useState([]);  // setting user info 
  const [modal, setModal] = useState(false);

  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const logout = async () => {
    // if used in more components, this should be in context 
    // axios to /logout endpoint 
    setAuth({});
    navigate('/linkpage');
  }
const handleLogIn=()=>{
  setAuth({});
  navigate('/LoginForm');
}

//
const handleAdminDashboard=()=>{
  setAuth({});
  navigate('/HomeAdmin');
}

//handleUserDashboard
const handleUserDashboard=()=>{
  setAuth({});
  navigate('/HomeUser');
}

  const navigateToAddNewTask = (props) => {
    // 👇️ navigate to /contacts
    navigate('/AddNewTask');
  };
  // Getting User Info during loading of the Page
    
  useEffect(() => {
    const getcategory = async () => {
      const res = await fetch("https://printsundar.online/loginAPI/getCRMUsers");
      const getdata = await res.json();
      setUserInfo(getdata);
      // console.log(getdata);
    };

    getcategory();
  },[]);
  
  const onClickProductSelected=(name, email, phone)=>{
   console.log("Button Clicked.." , {name} , {email}, {phone});
   }
    

   return (
    <div >
      <header>
           <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
                <Container>
                  
                <Navbar.Brand href="#home">Sundar Print Admin DashBoard</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="justify-content-end" style={{ width: "100%"}}>
                        <Nav.Link href="#features" >Features</Nav.Link>
                        <Nav.Link href="#pricing">Pricing</Nav.Link>
                                         
                        <Nav.Link onClick={handleLogIn}>
                            Log In
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                </Container>   
            </Navbar>
          </header>
          
        <div class="p-3 mb-2 bg-white text-dark">
         <section id="header" className="d-flex align-items-center">
          <div className="container-fluid nav-bg">
            <div className="row">
            <div className="col-11 mx-auto pt-5">
            <div className='row'>
              <div className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-1">
                <h1> Grow Your Business with <strong classNamw="text-primary">Sundar Print</strong></h1>
                <h2 className="my-3"> Sundar Print is the gateway of opportunitities.. Join Us</h2>
                <div className="mt-3">
                  <a href =" " className="btn btn-outline-primary btn-rounded" data-mdb-ripple-color="dark">Get Started</a>
                </div>
              </div>
              <div className='col-lg-6 order-1 order-lg-2 header-img' >
                  <img src={Web} className='img-fluid animated' alt='home img'/>
              </div>
              </div>
            </div>
            </div>
          </div>
          </section>
          </div>
          <MDBFooter bgColor='light' className='text-center text-lg-left'>
      <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 111, 255, 1)' }}>
        &copy; {new Date().getFullYear()} Copyright:{'  '}
        <a className='text-dark' href='https://printsundar.com/'>
         printsundar.com
        </a>
      </div>
    </MDBFooter>
       </div>
    )
 }
export default Home;