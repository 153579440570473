import React from 'react'
import styled from "styled-components";
import Koala from '../src/img/Koala.jpg';

const UserImage = styled.img`
    width: 100px;
    height: 100px;
    padding: 2px;
    border-radius: 50px;
`;
const TextHeader2 = styled.h2`
font-size:  1.5em;
text-align: center;
color: blue;
margin-left: 2px;

`;
const NavuserPic = styled.div`
   background: #4178d9;
   width: 100px;
   height: 100px;
   border: solid 1px #c74a28;
   margin-left: 12px;
  margin-top: 12px;
  align-items: flex-start;
  justify-content: start;
  border-radius: 80px;
`;
const NavUser = styled.div`
  background: #9ca5b5;
  margin-top: 1px;
  width: (100hh-100);
  margin-bottom: 0.5px;
  display: flex;
  margin-left: 205px;
  justify-content: left;
  align-items: center;
  border:solid 1px #0000ff;

`;
const UserInfoDiv = styled.div`
display: flex;
max-width: 100%;
max-height: 100%;

`;

const NavUserDesc = styled.div`
  
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

const NavOrderDescLeft = styled.form`
 flex-direction: column;
 height: 500px;
  width: (100hh-100);
  margin-left: 205px;
  margin-top:1px;
  background-color:#6eb5c4;
  border-radius: 10px;

  `;
  const TextHeader1 = styled.h1`
  font-size:  2.0em;
  text-align: center;
  justify-content: center;
  color: blue;
   `;

const UserLabel = styled.h1`
  font-size:  1.0em;
  color: black;
  margin-right: 5px;
   `;

const TextHeader3 = styled.h3`
    font-size:  2em;
    text-align: center;
    color: blanchedalmond;
   margin-left: 2px;
  
   `;
  

const InputTextBox = styled.input.attrs(props => ({
  // we can define static props
  type: "text",
  // or we can define dynamic ones
  size: 28 || "0.1em",
  
}))`
  color: black;
  font-size: 1em;
  border: 1px solid black;
  border-radius: 2px;

  /* here we use the dynamically computed prop */
  margin: ${props => props.size} + 5px;
  padding: ${props => props.size};
  margin-right: 5px;
  margin-bottom: 5px;
  align-items:center;
`;
const InProgressOrderData =[

  {orderid:'ACD102', regno:'WB16AA 9554', model: 'HeroHonda', worktype:'Basic', owner:'B K Bairagi', phoneno:'9433510469', bookingdate:'21st July', Status: 'In Progress', assignedto:'Arun Kumar'},
  {orderid:'ACD102', regno:'WB16AA 9554', model: 'HeroHonda', worktype:'Basic', owner:'B K Bairagi', phoneno:'9433510469', bookingdate:'21st July', Status: 'In Progress', assignedto:'Arun Kumar'},
  
]
const TodayOrderData =[

  {orderid:'ACD102', regno:'WB16AA 9554', model: 'HeroHonda', worktype:'Basic', owner:'B K Bairagi', phoneno:'9433510469', bookingdate:'21st July', Status: 'Pending', assignedto:'NULL'},
  {orderid:'ACD102', regno:'WB16AA 9554', model: 'HeroHonda', worktype:'Basic', owner:'B K Bairagi', phoneno:'9433510469', bookingdate:'21st July', Status: 'Pending', assignedto:'NULL'},
  {orderid:'ACD102', regno:'WB16AA 9554', model: 'HeroHonda', worktype:'Basic', owner:'B K Bairagi', phoneno:'9433510469', bookingdate:'21st July', Status: 'Pending', assignedto:'NULL'},
  
]

export const OrderDetails = () => {
  return (
    <>
      <NavUser>
      <NavuserPic>
        <UserImage src={Koala} />
      </NavuserPic>
      <NavUserDesc>
      <TextHeader1 > Welcome to OrderDetails page.</TextHeader1>
      </NavUserDesc>
      </NavUser>
         <NavOrderDescLeft>
              <TextHeader3> In Progress Tasks </TextHeader3>
              <table className='table table-primary table-bordered'>
              <thead>
                <tr>
                <th> Order Id ..</th>
                <th> Reg. No</th>
                <th> Model</th>
                <th> Work Type</th>
                <th> Owner</th>
                <th> phone No</th>
                <th> Booking Date</th>
                <th> Status</th>
                <th> Assigned To</th>
                </tr>
              </thead>
              <tbody>
                {
                  InProgressOrderData.map((item,i)=>
                  <tr key={i}>
                    <td>{item.orderid}</td>
                    <td>{item.regno}</td>
                    <td>{item.model}</td>
                    <td>{item.worktype}</td>
                    <td>{item.owner }</td>
                    <td>{item.phoneno }</td>
                    <td>{item.bookingdate }</td>
                    <td>{item.Status }</td>
                    <td>{item.assignedto}</td>
                    <td><button type="button" class="btn btn-primary">Done</button></td>
                  </tr>)
                }
              </tbody>
            </table>

            <TextHeader3> Task List for Today </TextHeader3>

            <table className='table table-primary table-bordered'>
            <thead>
            <tr>
            <th> Order Id ..</th>
            <th> Reg. No</th>
            <th> Model</th>
            <th> Work Type</th>
            <th> Owner</th>
            <th> phone No</th>
            <th> Booking Date</th>
            <th> Status</th>
            <th> Assigned To</th>
            </tr>
          </thead>
          <tbody>
            {
              TodayOrderData.map((item,i)=>
              <tr key={i}>
                <td>{item.orderid}</td>
                <td>{item.regno}</td>
                <td>{item.model}</td>
                <td>{item.worktype}</td>
                <td>{item.owner }</td>
                <td>{item.phoneno }</td>
                <td>{item.bookingdate }</td>
                <td>{item.Status }</td>
                <td>{item.assignedto}</td>
                <td><button type="button" class="btn btn-primary">Assign</button></td>
              </tr>)
            }
          </tbody>
          </table>
      </NavOrderDescLeft>  
  
    </>
  )
}


