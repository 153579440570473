import {React,  useState, useEffect} from 'react'
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Button } from 'bootstrap';

function CountriesTable() {

    const [countries, SetCountries] = useState([]);

    const getCountries=async()=>{
     try{
        const response = await axios.get("https://restcountries.com/v2/all");
        SetCountries(response.data);
     }catch(error)
     {
         console.log(error);
     }
  
    }
    const column =[
  
        {
          name: "Country Name",
          selector: row=>row.name,
          FontFace: 20
        },
        {
          name: "Region",
          selector: row=>row.region,
          FontFace: 20
        },
        {
          name: "Capital",
          selector: row=>row.capital,
          FontFace: 20
        },
        {
          name: "Flag",
          selector: (row)=> <img width={50} height={50} src={row.flag}/>,
        },
        {
            name: "Action",
            cell: row=> <button type="button" class="btn btn-primary" onClick={()=>alert(row.name)}>Edit</button>,
            cell: row=> <button type="button" class="btn btn-primary" onClick={()=>alert(row.name)}>view</button>,
            cell: row=> <button type="button" class="btn btn-primary" onClick={()=>alert(row.name)}>Delete</button>,

          }
      
        ]
        
        let styleobj = { "font-size": '15px' } //try to set the font-size here

        useEffect(()=>{
          getCountries();
      
        },[]);

  return (
    <DataTable title = "Country List" columns={column} 
    data= {countries} 
    font-weight='bold'
    pagination 
    fixedHeader 
    fixedHeaderScrollHeight='300px' 
    selectableRows
    selectableRowsHighlight
    highlightOnHover
    subHeader
    subHeaderComponent= {<input type="text" placeholder='Search here..' className='w-25 form-control'/>}
    subHeaderAlign='left'
    />
  );
}

export default CountriesTable