import React, { useState, useEffect } from 'react'
import Modal from "react-bootstrap/Modal";
import { Container, Form, Button, Row , Col } from 'react-bootstrap'
import toastr from 'cogo-toast';
import validator from 'validator'
import axios from 'axios';
//import ProgressDialog from 'react-native-progress-dialog';

const AddNewUserModalCustom =({isOpen, hideModal}) =>{
 
  console.log("Called AddNewTaskModalCustom Ln 11 value of " + isOpen);
  const [phoneNo, setPhoneNo] = useState('');
  const [passWord, setPassword] = useState('');
  const [repassWord, setRePassword] = useState('');
  const [userName, setUserName] = useState('');
  const [userRole, setUserRole] = useState('User');
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgres] = useState(false);

  const [UserData, setUserData] = useState({
    datetime: "",
    is_now_login: "No",
    oldPassword: "",
    passWord: "",
    phoneNo: "",
    today_login_Attempt: 0,
    userName: "",
    userRole: "",
    userToken: "",
    wrong_login_attempt: 0
  })
  
    const handleSelectUserRole=async(evt, param)=>{
     
      setUserRole(param);
   }
    const handleReenterPassword=async(evt, param)=>{
     
      setRePassword(param);
    }
    const handlePassword=async(evt, param)=>{
    
      setPassword(param);
    }
    const handleUserPhone=async(evt, param)=>{
      console.log("Ln 45 Phone Number =" + param);
      setPhoneNo(param);
    }
    const handleUserName=async(evt, param)=>{
     
      setUserName(param);
    } 
    
    const reSetFormData=async()=>{
      setPhoneNo('');
      setPassword('');
      setRePassword('');
      setUserName('');
      setUserRole('');
    }
    // Code to Save data by rest service call starts below....
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

    const checkClientValidation =()=>{

     
      if(phoneNo.length !== 10 )
      {
          alert("Please Enter a Valid Phone Number..");
          return false;
      }
      if(passWord.length === 0)
      {
          alert("Please Enter Password Id..");
          return false;
      }
      if(repassWord.length === 0)
      {
          alert("Please Re-Enter Password ..");
          return false;
      }
      if(userName.length === 0)
      {
          alert("Please Enter userName..");
          return false;
      }
       
      return true;

    }    

    let handleSubmit = async (e) => {
      e.preventDefault();
      setShowProgres(true);
      if(!checkClientValidation()){

          return;

      }
      let errorMsg = '';
      const allFieldsFilled = () => {
        return(phoneNo !==''|| passWord !==''|| repassWord !==''|| userName !=='')
      };

    if(!allFieldsFilled)
    {
      errorMsg = 'Please fill out all the fields.';
      console.log(errorMsg);
    }
    else
    {
      try {
        let res = await fetch("https://printsundar.online/loginAPI/addTaskUser", {
          method: "POST",
          headers: {'Content-Type': 'application/json', 'charset': 'utf-8'},
          body: JSON.stringify({  
            userId:(Math.floor(Math.random() * 10000) + 10000).toString().substring(1),
            datetime: new Date(),
            is_now_login:"No",
            oldPassword: "Abcd1234",
            passWord:passWord, 
            phoneNo:phoneNo,
            today_login_Attempt:0,
            userName:userName,
            userRole:userRole,
            userToken:"ABCD123354",
            wrong_login_attempt:0,
          }),
        });
        let resJson = await res.json();
        //setShowProgres(false);
        if (res.status === 200) {
           //setTaskData([]);
            //console.log("Task added successfully");
            setMessage("User added successfully");
            toastr.success('New User added successfully!', {position : 'top-right', heading: 'Done'});    
            reSetFormData();
            hideModal();
         
        } else {
          //console.log("Some error occured");
          setMessage("Error occured...");
          toastr.error('Error to add User!', {position : 'top-right', heading: 'Failed'});
        }
      } catch (err) {
        console.log(err);
      }
    } 
    };
    
    // @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // Code to Save data by rest service call ends above....
    return (
      <> <Modal 
      show={isOpen} 
      onHide={hideModal} 
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
     >
     <Modal.Header style={{backgroundColor: "skyBlue"}} closeButton>
      <Modal.Title id="contained-modal-title-vcenter" color="white">
       Register New User..
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="show-grid">
   
             <Form >
             <Row>
    <Col>
    <Form.Group className="mb-3" controlId="UserName">
        <Form.Label>User Name</Form.Label>
        <Form.Control type="text" placeholder="UserName"  name="UserName" value={userName}
        onChange={event=>handleUserName(event, event.target.value)}
        />
      </Form.Group>
    </Col>
    <Col>
    <Form.Group className="mb-3" controlId="phoneNo">
        <Form.Label>Phone No</Form.Label>
        <Form.Control type="number" placeholder="PhoneNo"  name="PhoneNo" value={phoneNo}
        onChange={event=>handleUserPhone(event, event.target.value)}
        />
      </Form.Group>
    </Col>
   </Row>
     <Row>
    <Col>
    <Form.Group className="mb-3" controlId="Password">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" placeholder="Password"  name="Password" value={passWord}
        onChange={event=>handlePassword(event, event.target.value)}
        />
      </Form.Group>
    </Col>
    <Col>
    <Form.Group className="mb-3" controlId="Password">
        <Form.Label>Re Enter Password</Form.Label>
        <Form.Control type="password" placeholder="rePassword"  name="rePassword" value={repassWord}
        onChange={event=>handleReenterPassword(event, event.target.value)}
        />
      </Form.Group>
    </Col>
   </Row>
     <Row>
    <Col>
    <Form.Group className="mb-3" controlId="selectUserRole">
    <Form.Label>User userRole</Form.Label>
      <Form.Control as="select"  
      name="userRole" 
      value={ userRole }
      onChange={event=>handleSelectUserRole(event, event.target.value)}
       >
        <option value="Admin">Admin</option>
        <option value="Designer">Designer</option>
        <option value="Marketing">Marketing</option>
        </Form.Control>
      </Form.Group>
    </Col>
   
   </Row>
     <div className="message">{message ? <p>{message}</p> : null}</div>
    </Form>
      
        </Modal.Body>
      <Modal.Footer>
        <Button color="primary" onClick={hideModal}>Cancel</Button>
        <Button color="dark" onClick={handleSubmit}>Submit</Button>
      </Modal.Footer>
    </Modal>
    </>
    )
  }

export default AddNewUserModalCustom