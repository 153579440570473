import {React,  useState, useEffect} from 'react'
import styled from "styled-components";
import {Routes, Route, useNavigate} from 'react-router-dom';
import {Table, Button} from 'react-bootstrap'
import Sidebar from '../src//components/Sidebar';
import axios from 'axios';
import toastr from 'cogo-toast';
import DataTable, { createTheme } from 'react-data-table-component';
import ModalCustom from '../src/components/ModalCustom';
import useAuth from "../src/components/hooks/useAuth";
import AddNewTask from '../src/components/AddNewTask';
//import RegisterUser from './components/RegisterUser';
import AddNewUserModalCustom from './components/AddNewUserModalCustom'

const ExtraPadding = styled.div`
margin-left: 20px;
`;
const ExtraleftPadding = styled.div`
margin-left: 250px;
`;
const ExtraBottomPad = styled.div`
margin-bototom: 20px;
`;
const DropDownStyle = styled.div`
  margin: 100;
  width: 250;
`;
const TaskButton = styled.button`
margin-left: 10px;
background:green;
font-size: 1em;
  margin: 1em;
  width: 100px;
  height: 40px;
  padding: 0.25em 1em;
  border: 2px solid white;
  border-radius: 3px;

`;
const NavUser = styled.div`
  background: #4d9dff;
  margin-top: 1px;
  margin-bottom: 15px;
  margin-left: 200px;
  margin-right: 5px;
  height: 350px;
  width:85%;
  border:solid 1px #0000ff
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const LabelUser = styled.span`
  margin-left: 10px;
`;
const NavTop = styled.div`
  background: #0A1063;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const InputTextBox = styled.input.attrs(props => ({
  // we can define static props
  type: "text",
  placeholder: "Search Tasks",
  // or we can define dynamic ones
  size: 40 || "0.1em",
  
}))`
  color: black;
  font-size: 1em;
  border: 1px solid black;
  border-radius: 2px;

  /* here we use the dynamically computed prop */
  margin:  5px;
  padding: 5px;
  margin-left: 20px;
  margin-right: 15px;
  margin-bottom: 5px;
  align-items:left;
`;

export const Users = () => {

  console.log("Inside Users Class Ln 87");
  const [CRMUsers, SetCRMUsers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const[userId, SetuserId] = useState(1);
  var [date,setDate] = useState(new Date());

  const navigate = useNavigate();

  const navigateToUserDetails = (props) => {
    // 👇️ navigate to /contacts
    navigate('/UserDetails');
  };
  const { auth } = useAuth();
  useEffect(() => {
    console.log("Inside useEffect)_ of Users Class Ln 101");
    var timer = setInterval(()=>setDate(new Date()), 1000 )
    return function cleanup() {
        clearInterval(timer)
    }

});
  const showModal = () => {
    setIsOpen(true);
  };
    
  const hideModal = () => {
    setIsOpen(false);
  };
  
  const navigateToAddUser = (props) => {
    // 👇️ navigate to /contacts
    //navigate('/Re');
    //<RegisterUser isOpen={isOpen} hideModal={hideModal} taskId={taskId}/>
    showModal();
  };

  const handleEditClick=async(evt, param)=>{
    const taskId = param; 
    //console.log("recived Task Id: "+ taskId);
    //SetTaskId(taskId);
    showModal();
  }
  let handleDeleteClick = async (evt, param) => {
    //e.preventDefault();
    let errorMsg = '';
    const rowId = param; 
    const baseURL = "/removeTaskUser";
    
    //console.log(" ****** Delete ID= "+ rowId);
    
    try {
      let res = await fetch(`${baseURL}/${rowId}`, { method: "delete" }).then(async response => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        const data = isJson && await response.json();

        // check for error response
        if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }

        toastr.success('User deleted successfully!', {position : 'top-right', heading: 'Done'}); 
        getCRMUsers();
    })}
    catch(error) 
    {
        //element.parentElement.innerHTML = `Error: ${error}`;
        console.error('There was an error!', error);
    };
  }
  const getCRMUsers=async()=>{
    try{
       const response = await axios.get("https://printsundar.online/loginAPI/getCRMUsers");
       //const response = await axios.get("/tasks");
       SetCRMUsers(response.data);
    }catch(error)
    {
        console.log(error);
        alert('Please check network connections..'+ error);
    }
 
   }

  const onClickProductSelected=(name, email, phone)=>{
   console.log("Button Clicked.." , {name} , {email}, {phone});
   }
   
  // Handle Delete request ends above....
  const column =[
  
    {
      name: "User Id",
      selector: row=>row.userId,
      sortable: true,
      wrap: true,
      FontFace: 150,
      maxWidth: "80px"
    },
    {
      name: "User Name",
      selector: row=>row.userName,
      FontFace: 150,
      sortable: true,
      wrap: true,
      maxWidth: "200px"
    },
    {
      name: "Phone No",
      FontFace: 150,
      selector: row=>row.phoneNo,
      sortable: true,
      wrap: true,
      maxWidth: "130px"
    },
    {
      name: "Password",
      FontFace: 200,
      selector: row=>row.passWord,
      sortable: true,
      wrap: true,
      maxWidth: "150px"
    },
    {
      name: "User Role",
      FontFace: 350,
      selector: row=>row.userRole,
      sortable: true,
      wrap: true,
      maxWidth: "100px",
      
    },
    {
        name: "Action Edit",
        cell: row=> <button type="button" className="btn btn-primary" onClick={event=>handleEditClick(event, row.userId)}>Edit</button>,
        //cell: row=> <button type="button" className="btn btn-primary" onClick={showModal}>Edit</button>,
        maxWidth: "100px",
      },
      {
        name: "Action Delete",
        cell: row=> <button type="button" className="btn btn-danger" onClick={event=>handleDeleteClick(event, row.userId)}>Delete</button>,
        maxWidth: "150px",
      }
     
  
    ]
    
    let styleobj = { "font-size": '25px' } //try to set the font-size here

    useEffect(()=>{
      console.log("Inside useEffect)_ of Users Class Ln 237");
      getCRMUsers();
    },[]);

return (
<div>
<Sidebar/>
<NavUser>
<NavTop>
      <h2
            style={{ textAlign: "left", 
                     marginLeft: "5px", 
                     marginright: "25px",
                     color: "white" }}
          >
           Users Dashboard
          </h2>
          <ExtraPadding/>
          <button type="button" class="btn btn-success" onClick={() => 
          navigateToAddUser()}>Register User</button>
                              
      </NavTop>
  <DataTable title = "User List" columns={column} 
    data= {CRMUsers} 
    theme="solarized"
    pagination 
    selectableRows
    selectableRowsHighlight
    highlightOnHover
    subHeader
    subHeaderComponent= {<input type="text" placeholder='Search here..' className='w-25 form-control'/>}
    subHeaderAlign='left'
    customStyles={{
      subHeader: {
        style: { fontWeight: "bold", color: "red", fontSize: "36px" }
      }
    }}
    />
<AddNewUserModalCustom isOpen={isOpen} hideModal={hideModal} userId={userId}/>
</NavUser>
</div>
    
);
}
